import uuid from 'uuid';
import { reactive } from '@vue/composition-api';

export const usePromise = (promiseGenerator) => {
  const state = reactive({
    id: uuid(),
    promise: null,
    pending: false,
    result: null,
    error: null,

    execute () {
      state.id = uuid();
      const lastId = state.id;

      state.error = null;
      state.pending = true;
      state.promise = promiseGenerator()
        .then(result => {
          state.result = result;

          return result;
        }).catch(error => {
          state.error = error;
          throw error;
        }).finally(() => {
          if (lastId === state.id)
            state.pending = false;
        });

      return state.promise;
    },
  });

  return state;
};
