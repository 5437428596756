import { computed, reactive } from '@vue/composition-api';
import Vue from 'vue';

function useForm (validationsMap) {
  const fields = Object.entries(validationsMap).map(
    ([fieldName, { validations = [], value = null }]) => ({
      fieldName, state: useField(value, validations),
    }),
  );

  const form = reactive({
    isValid: computed(() => fields.every(field => field.state.isValid)),
  });

  fields.forEach(({ fieldName, state }) => {
    Vue.set(form, fieldName, state);
  });

  return form;
}


function useField (value, validations = []) {
  const fieldState = reactive({
    isValid: computed(() => {
      return validationResultsRef.value.every(validation => validation.isValid);
    }),
    value,
  });

  const validationResultsRef = computed(() => {
    return validations.map(validation => validation(fieldState.value));
  });

  return fieldState;
}


function useRequiredValidation () {
  return function validate (value) {
    return { isValid: !!value };
  };
}

export {
  useForm,
  useRequiredValidation,
};
