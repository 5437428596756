import axios from 'axios';
import { applyConfig } from '@shared/axios-config-default';

const defaultAxios = axios.create();
applyConfig(defaultAxios);

// This assignment will be removed later. Do not depend on it.
window.axios = defaultAxios;

const { get, post, put, patch } = defaultAxios;
export { get, post, put, patch };
export default defaultAxios;
