import { Swiper } from 'swiper';
import 'swiper/swiper.min.css';
import './style.scss';

document.addEventListener('DOMContentLoaded', () => {
  const containers = document.querySelectorAll('.main-page-swiper');

  containers.forEach(container => {
    const options = container.dataset.swiper ? JSON.parse(container.dataset.swiper) : {};

    new Swiper(container, {
      spaceBetween: 15,
      slidesPerView: 1.1,
      ...options,
    });
  });
});
