import { get } from '@shared/transforming-keys-axios';
import { testimonialsPath } from '../../testimonials/paths.js.erb';
import _ from 'lodash';

const SET_INITIALIZED = 'SET_INITIALIZED';
const SET_LOADING_STATE = 'SET_LOADING_STATE';
const SET_COURSES = 'SET_COURSES';
const SET_FILTERS = 'SET_FILTERS';
const ADD_TESTIMONIALS_BLOCK = 'ADD_TESTIMONIALS_BLOCK';
const SET_FILTER_BY_AUTHOR = 'SET_FILTER_BY_AUTHOR';
const SET_FILTER_BY_COURSE = 'SET_FILTER_BY_COURSE';
const SET_MORE_LOADING_STATE = 'SET_MORE_LOADING_STATE';
const SET_MODAL_STATE = 'SET_MODAL_STATE';
const SET_COURSE_SHOW_MORE = 'SET_COURSE_SHOW_MORE';

export default {
  namespaced: true,

  state: {
    initialized: false,
    show: true,
    showModal: false,
    courses: [],
    filters: {
      selectedAuthor: '',
      selectedCourse: '',
      authorFilters: [],
      courseFilters: [],
    }
  },

  getters: {
    coursesByAuthor: state => {
      const { selectedAuthor } = state.filters;
      return state.courses.filter(course => course.authorSlug === selectedAuthor);
    },

    coursesBySlug: state => {
      const { selectedCourse } = state.filters;
      return state.courses.filter(course => course.slug === selectedCourse);
    },

    filteredCourses: (state, getters) => {
      const { selectedAuthor, selectedCourse } = state.filters;
      if (selectedAuthor) return getters.coursesByAuthor;
      if (selectedCourse) return getters.coursesBySlug;
      return state.courses;
    },

    nextVideoTestimonialsSlice: (_, { videoTestimonialsLoadedCount }) => course => {
      const offset = videoTestimonialsLoadedCount(course);
      return course.videoTestimonials.slice(offset, offset + 3);
    },

    nextTextTestimonialsSlice: (_, { textTestimonialsLoadedCount }) => course => {
      const offset = textTestimonialsLoadedCount(course);
      return course.textTestimonials.slice(offset, offset + 2);
    },

    videoTestimonialsLoadedCount: () => course => {
      return course.testimonials.reduce((count, block) => count + block.videoTestimonials.length, 0);
    },

    textTestimonialsLoadedCount: () => course => {
      return course.testimonials.reduce((count, block) => count + block.textTestimonials.length, 0);
    }
  },

  mutations: {
    [SET_INITIALIZED](state) {
      state.initialized = true;
    },

    [SET_LOADING_STATE](state, show) {
      state.show = show;
    },

    [SET_COURSES](state, courses) {
      state.courses = courses.map(course => {
        return ({
          ...course,
          nextVideoTestimonials: 0,
          nextTextTestimonials: 0,
          showMore: true,
          show: true,
          showLoading: false,
          testimonials: [],
          skipVideo: false });
      });
    },

    [SET_FILTERS](state, filters) {
      const { authorFilters, courseFilters } = filters;
      state.filters.authorFilters = authorFilters;
      state.filters.courseFilters = courseFilters;
    },

    [ADD_TESTIMONIALS_BLOCK]({ courses }, { courseId, videoTestimonials, textTestimonials }) {
      const course = courses.find(course => course.id === courseId);
      course.testimonials.push({ videoTestimonials, textTestimonials });
    },

    [SET_COURSE_SHOW_MORE]({ courses }, { courseId, value }) {
      courses.find(course => course.id === courseId).showMore = value;
    },

    [SET_FILTER_BY_AUTHOR](state, author) {
      state.filters.selectedAuthor = author;
    },

    [SET_FILTER_BY_COURSE](state, course) {
      state.filters.selectedCourse = course;
    },

    [SET_MORE_LOADING_STATE](state, { courseId, showLoading }) {
      let index = state.courses.findIndex(course => course.id === courseId);
      if (index >= 0) {
        state.courses[index].showLoading = showLoading;
      }
    },

    [SET_MODAL_STATE](state, modalVisibility) {
      state.showModal = modalVisibility;
    }
  },

  actions: {
    initialize({ commit, dispatch }) {
      get(testimonialsPath).then(response => {
        const { courses, authorFilters, courseFilters } = response.data;
        commit(SET_COURSES, courses);

        courses.forEach(course => {
          dispatch('moreTestimonials', { courseId: course.id });
        });

        commit(SET_FILTERS, { authorFilters, courseFilters });
        commit(SET_LOADING_STATE, false);
        setTimeout(() => commit(SET_INITIALIZED), 600);
      });
    },

    moreTestimonials({ state, commit, getters }, { courseId }) {
      commit(SET_MORE_LOADING_STATE, { courseId, showLoading: true });

      const course = state.courses.find(course => course.id === courseId);
      const {
        nextVideoTestimonialsSlice,
        nextTextTestimonialsSlice,
        videoTestimonialsLoadedCount,
        textTestimonialsLoadedCount,
      } = getters;

      let videoTestimonials = nextVideoTestimonialsSlice(course);
      let textTestimonials = nextTextTestimonialsSlice(course);
      const videoTestimonialsCount = videoTestimonialsLoadedCount(course);
      const textTestimonialsCount = textTestimonialsLoadedCount(course);

      setTimeout(() => {
        commit(SET_MORE_LOADING_STATE, { courseId, showLoading: false });

        commit(ADD_TESTIMONIALS_BLOCK, { courseId, videoTestimonials, textTestimonials });

        if (!textTestimonials.length && course.videoTestimonials.length > videoTestimonialsCount) {
          videoTestimonials = nextVideoTestimonialsSlice(course);
          commit(ADD_TESTIMONIALS_BLOCK, { courseId, videoTestimonials, textTestimonials: [] });
        } else if (!videoTestimonials.length && course.textTestimonials.length > textTestimonialsCount) {
          textTestimonials = nextTextTestimonialsSlice(course);
          commit(ADD_TESTIMONIALS_BLOCK, { courseId, videoTestimonials: [], textTestimonials });
        }

        if (!textTestimonials.length && !videoTestimonials.length) {
          commit(SET_COURSE_SHOW_MORE, { courseId, value: false });
        }
      }, 600);
    },

    filterByAuthor({ commit }, author) {
      commit(SET_LOADING_STATE, true);
      commit(SET_FILTER_BY_COURSE, '');
      commit(SET_FILTER_BY_AUTHOR, author);
      setTimeout(() => commit(SET_LOADING_STATE, false), 800);
    },

    filterByCourse({ commit }, course) {
      commit(SET_LOADING_STATE, true);
      commit(SET_FILTER_BY_AUTHOR, '');
      commit(SET_FILTER_BY_COURSE, course);
      setTimeout(() => commit(SET_LOADING_STATE, false), 800);
    },

    modalState({ commit }, modalVisibility) {
      commit(SET_MODAL_STATE, modalVisibility);
    }
  }
};
