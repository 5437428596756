import { get } from '@shared/transforming-keys-axios';
import { defineSetters, withPending, useCancelToken, cancelledRequestHandler } from '@shared/vuex/utils';
import { serverTimePath } from '@app_shared/paths.js.erb';
import moment from 'moment';

import initServerTimeChannel from '@app_shared/init-server-time-channel';

let channel;
let tickInterval;

export default {
  namespaced: true,

  state: {
    valuePending: false,
    value: Date.now(),
    lastSynchronizedAt: Date.now()
  },

  getters: {
    moment: ({ value }) => moment(value),

    desynchronized: ({ value, lastSynchronizedAt }) => ((value - lastSynchronizedAt) / 1000) > 60
  },

  mutations: {
    ...defineSetters([
      'valuePending',
      'value',
      'lastSynchronizedAt'
    ]),

    INCREMENT (state) {
      state.value += 1000;
    },

    RESOLVE (state, value) {
      state.valuePending = false;
      state.value = value;
      state.lastSynchronizedAt = value;
    }
  },

  actions: {
    initSynchronizeChannel ({ commit, dispatch }) {
      channel = channel || initServerTimeChannel({
        onReceived: (value) => {
          console.log('Synchronized via WS');
          commit('RESOLVE', value);
        },

        onFailure: () => {
          commit('SET_VALUE_PENDING', false);
        }
      });
    },

    initClientTicks ({ commit, state, getters, dispatch }) {
      tickInterval = tickInterval || setInterval(() => {
        commit('INCREMENT');

        const { valuePending } = state;
        const { desynchronized } = getters;

        if (desynchronized && !valuePending) {
          dispatch('synchronizeManually');
        }
      }, 1000);
    },

    async synchronizeManually ({ commit }) {
      const request = get(serverTimePath, { cancelToken: useCancelToken(serverTimePath) });
      withPending('VALUE', commit, request).then(({ data }) => {
        commit('RESOLVE', data);
        console.log('Synchronized manually');
      }).catch(cancelledRequestHandler).catch(error => {
        console.error(error);
      });
    }
  }
};