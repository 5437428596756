<template lang="pug">
  SidebarWrapper(:position="position")
    SidebarSecondaryHeader
      i.fa.fa-shopping-cart.mr-2
      | Корзина

    ScrollWrapper.overflow-x-hidden(:dynamic-flex="true")
      TransitionGroup(tag="ul" class="list-unstyled" name="slide-fade")
        EduMaterial(v-for="material in cart.materials" :key="material.id" :material="material" @remove-click="cart.removeMaterial(material.id)")

      ItemMissing(:should-show="cart.isEmpty")
        | Корзина пуста

    .cart-footer(v-if="!cart.isEmpty")
      .summary-container
        | Итого:
        .summary-wrapper
          | {{cart.totalPrice | money}}

      a.btn.btn-green-gradient(:href="materialsCartPath") Оформить заказ
</template>

<script>
  import SidebarWrapper from '../shared/SidebarWrapper';
  import SidebarSecondaryHeader from '../shared/SidebarSecondaryHeader';
  import ItemMissing from '../shared/ItemMissing';
  import EduMaterial from '@app/shopping_cart/EduMaterial';

  import sidebarWrapperMixin from '../shared/mixins/sidebar-wrapper-mixin';

  import { cart } from '@app/shopping_cart';
  import { materialsCartPath } from '@app/edu_materials/paths.js.erb';

  export default {
    mixins: [sidebarWrapperMixin],

    components: {
      SidebarWrapper,
      SidebarSecondaryHeader,
      ItemMissing,
      EduMaterial,
    },

    setup () {
      return {
        cart,
        materialsCartPath,
      };
    },
  };
</script>

<style scoped lang="scss">
  // Slide fade
  .slide-fade-enter-active, .slide-fade-leave-active {
    transition: all .3s ease-out;
  }

  .slide-fade-enter, .slide-fade-leave-to {
    opacity: 0;
    transform: translateX(10px);
  }

  .overflow-x-hidden ::v-deep .scrollable-area {
    overflow-x: hidden;
  }

  .cart-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background-color: white;
    padding-right: 16px;
    min-height: 114px;

    @include media-breakpoint-down(xs) {
      align-items: center;
      padding-right: 0;
    }

    .summary-container {
      padding: 6px 18px;
      border-bottom: 1px solid #f0f0f0;
      margin-bottom: 12px;
      .summary-wrapper {
        font-size: 21px;
        display: inline-block;
        margin-left: 4px;
      }
    }

    > a.btn {
      font-weight: 500;
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;
    }
  }
</style>
