<template lang="pug">
  li.cart-material
    .cart-material-section.material-img
      img(:src="material.imageUrl" :alt="material.name")

    .cart-material-section.material-title
      a.link-unstyled(:href="material.url")
        | {{material.name}}

    .cart-material-section.material-price
      span(v-if="priceTag")
        | {{priceTag | money}}
      span.text-danger(v-else)
        | Не продается

    .cart-material-section.material-remove
      span.remove(@click="confirmRemoveProduct")
        i.fa.fa-trash
</template>

<script>

  import { getEcommercePrice } from '@app_shared/ecommerce.js.erb';

  export default {
    props: {
      material: {
        type: Object,
        required: true,
      },
    },

    methods: {
      confirmRemoveProduct () {
        if (confirm('Подтвердите удаление материала из корзины.')) {
          this.$emit('remove-click');
        }
      },
    },

    computed: {
      priceTag () {
        return getEcommercePrice(this.material);
      },
    },
  };
</script>

<style scoped lang="scss">
  .cart-material {
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #f0f0f0;
    align-items: stretch;
    background-color: white;

    @include media-breakpoint-down(xs) {
      padding-right: 0;
    }

    .cart-material-section {
      padding: 10px;
      display: flex;
      align-items: center;
    }

    .remove {
      cursor: pointer;
      visibility: hidden;
      color: $oslo-gray;
      opacity: 0.7;
      transition: opacity .3s;

      &:hover {
        opacity: 1;
      }

      &.removing {
        visibility: hidden;
      }
    }

    @include media-breakpoint-down(sm) {
      .remove {
        visibility: visible;
      }
    }

    &:hover {
      .remove {
        visibility: visible;
      }
    }

    .material-title {
      > a {
        line-height: 1.25em;
        word-break: break-word;
        display: block;
      }
    }

    .material-img {
      display: flex;
      align-items: center;

      img {
        width: 60px;
        height: 80px;
        object-fit: cover;
        border: 1px solid rgba(0, 0, 0, 0.2);
      }
    }

    .material-title {
      margin-right: auto;
    }

    .material-price {
      white-space: nowrap;
      font-weight: 500;
    }
  }
</style>
