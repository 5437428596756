<template lang="pug">
  .pm-plyr-wrapper(ref="rootRef" :class="{ hidden: !playerReady }")
    slot
      | SOMETHING WENT WRONG
</template>

<script>
  import Plyr from 'plyr';
  import { watch, ref, computed } from '@vue/composition-api';
  import { defaultPlyrOptions } from '@shared/default-plyr-options';
  import 'plyr/dist/plyr.css';
  import usePlyrMediaResumer from '@shared/composition-utils/plyr-media-resumer';

  export default {
    name: 'Plyr',

    props: {
      options: {
        type: Object,
        default: () => ({}),
      },

      savedProgressKey: {
        type: String,
        default: null,
      },
    },

    setup (props, { emit }) {
      const rootRef   = ref(null);
      const playerRef = ref(null);


      watch(rootRef, element => {
        if (!element) return;

        const resolvedOptions = ({
          ...defaultPlyrOptions,
          ...props.options,
        });

        playerRef.value = new Plyr(element.children[0], resolvedOptions);
        addEventListeners(playerRef.value, emit);

        if (props.savedProgressKey)
          usePlyrMediaResumer(props.savedProgressKey, playerRef.value);
      });

      return {
        rootRef,
        playerReady: computed(() => !!playerRef.value),
      };
    },
  };

  function addEventListeners (player, emit) {
    player.on('ready', event => emit('player', event.detail.plyr));
    player.on('playing', event => emit('playing', event));
    player.on('pause', event => emit('pause', event));
    player.on('loadedmetadata', event => emit('loadedmetadata', event));
    player.on('timeupdate', event => emit('timeupdate', event));
    player.on('canplay', event => emit('canplay', event));
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .plyr__controls__item[data-plyr="pip"], .plyr__controls__item.plyr__volume {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
</style>
