<template>
  <transition name="fade">
    <div id="sidebars_app" v-if="anySidebarOpen" :class="{ open: anySidebarOpen, closing: closing }"
         @touchstart="handleClose"
         @click="handleClose"
         ref="menuShadow"
    >

      <NavigationSidebar v-if="sidebarOpen('navigation')" position="left" />

      <NotificationsSidebar v-if="sidebarOpen('notifications')" position="right" />

      <ShoppingCartSidebar v-if="sidebarOpen('shopping_cart')" position="right" />

      <PersonalSidebar v-if="authenticated && sidebarOpen('personal')" position="right" />
    </div>
  </transition>
</template>

<script>
  /* global $ */

  import ShoppingCartSidebar from './shopping_cart_sidebar/app';
  import NotificationsSidebar from './notifications_sidebar/app';
  import PersonalSidebar from './personal_sidebar/app';
  import NavigationSidebar from './navigation_sidebar/app';

  import { mapGetters, mapMutations } from 'vuex';

  export default {
    data () {
      return {
        closing: false,
      };
    },

    components: {
      NotificationsSidebar,
      PersonalSidebar,
      NavigationSidebar,
      ShoppingCartSidebar,
    },

    computed: {
      ...mapGetters([
        'sidebarOpen',
        'anySidebarOpen',
        'authenticated',
      ]),
    },

    methods: {
      ...mapMutations([
        'closeSidebar',
      ]),

      handleClose (event) {
        if (event.target === this.$refs.menuShadow) {
          this.closeSidebar();
        }
      },
    },

    watch: {
      anySidebarOpen (newValue, oldValue) {
        if(newValue && !oldValue) {
          $('body').addClass('modal-open');
        } else if (!newValue && oldValue) {
          $('body').removeClass('modal-open');
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  #sidebars_app {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparentize(black, 0.7);
    z-index: 1030;
    touch-action: manipulation;
  }

  // Fade
  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;

    ::v-deep .sidebar-wrapper {
      transition: transform .3s;
    }
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;

    ::v-deep .sidebar-wrapper {
      &.left {
        transform: translate3d(-100%, 0, 0);
      }

      &.right {
        transform: translate3d(100%, 0, 0);
      }
    }
  }
</style>