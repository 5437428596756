<template lang="pug">
  a.cart-icon(
    v-if="cart.materialsCount > 0"
    href="#"
    :class="[{ animate }, 'nav-link']"
    @animationend="animate = false"
    @click.prevent="openSidebar('shopping_cart')"
  )
    span.cart-icon-counter {{cart.materialsCount}}
    i.fa.fa-shopping-cart
</template>

<script>
  import { cart } from '@app/shopping_cart';

  import { useMutations } from '@u3u/vue-hooks';
  import { ref, watch } from '@vue/composition-api';

  export default {
    setup () {
      const { openSidebar } = useMutations(['openSidebar']);

      const { animateRef } = useAnimation(cart);

      return {
        animate: animateRef,
        openSidebar,
        cart,
      };
    },
  };

  function useAnimation (cart) {
    const animateRef = ref(false);

    watch(() => cart.materialsCount, (now, was) => {
      if (now > was)
        animateRef.value = true;
    }, { immediate: true });

    return {
      animateRef,
    };
  }
</script>

<style lang="scss" scoped>
  .cart-icon {
    position: relative;
  }

  .cart-icon-counter {
    position: absolute;
    top: 17px;
    left: 4px;
    z-index: 1;
    display: inline-block;
    font-size: 13px;
    font-weight: bold;

    $size: 18px;
    width: $size;
    height: $size;
    line-height: $size;
    border: 1px solid #363f47;

    background: $amaranth;
    text-align: center;
    border-radius: 50%;
    color: white;
  }

  .animate {
    animation: bounce .45s 1;
  }

  @keyframes bounce {
    0% {
      transform: translateY(0);
    }

    30% {
      transform: translateY(-5px);
    }

    70% {
      transform: translateY(-5px);
    }

    100% {
      transform: translateY(0);
    }
  }
</style>
