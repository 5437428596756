/* global currentPath */
/* global $ */

import initLessonView from '../../../lesson/init-lesson-view';

import axios from '@shared/default-axios';
import localStorageOptions from '@shared/local-storage-options';

import { logoutPath } from '@app_shared/paths.js.erb';

import { scrollTo } from 'vue-scrollto';

import speechSynthesisChunker from '@app/lesson/speech-synthesis-chunker';

// Variables for ActionCable subscription
let lessonViewSubscription;

const homeworkSubmitHandler = {
  handle (promise, { commit }) {
    this.commit = commit;

    return promise.then(this.onSuccess.bind(this))
      .catch(this.onError.bind(this))
      .finally(this.always.bind(this));
  },

  onSuccess (response) {
    this.commit('updateHomework', response.data);
  },

  onError (error) {
    const { response } = error;
    const { data, status } = response;
    const { rejectedDueToStatusChange, availableToContinue, statusChanged } = data;

    if (rejectedDueToStatusChange) {
      this.commit('setSubmitRejectedDueToStatusChange');
      this.commit('setAvailableToContinue', availableToContinue);

      if (statusChanged) this.commit('setStatusChanged');
    } else if (status === 401) {
      const { reason = 'unauthenticated', current_email: currentEmail } = data;
      this.commit('setUnauthorized', { reason, currentEmail });
    } else {
      console.error('Homework submit error', error);
    }
  },

  always () {
    this.commit('updateHomework', { submitting: false });
  },
};

export default {
  loadInitial ({ commit, dispatch }) {
    commit('setLoaded', false);


    return axios.get(currentPath).then((response) => {
      dispatch('initialize', response.data);
    }).catch((error) => {
      console.log('Initial load error', error);
    });
  },

  initialize ({ commit, rootGetters, dispatch }, data) {
    commit('initialize', data);

    const options = localStorageOptions.fetch('lessonOptions');
    const { spellcheck } = options;

    dispatch('resolveInitialMainContentVariant');

    if (spellcheck === true || spellcheck === false) {
      commit('setSpellcheck', spellcheck);
    }

    if (!lessonViewSubscription && !rootGetters.userIsOuter) {
      lessonViewSubscription = initLessonView(data.id);
    }

    scrollTo('body', 0);
    window.lessonFallbackHandler.stop();
  },

  requestContentAccess () {
    lessonViewSubscription.requestAccess();
  },

  async resolveSpeechEnabled ({ commit }) {
    if ('speechSynthesis' in window) {
      const ruLangVariants = ['ru-RU', 'ru_RU'];

      let voices = speechSynthesis.getVoices();

      if (!voices.length) {
        await new Promise(resolve => speechSynthesis.onvoiceschanged = resolve);
        voices = speechSynthesis.getVoices();
      }

      commit('setSpeechEnabled', voices.some(voice => ruLangVariants.includes(voice.lang)));
    }
  },

  textToSpeech ({ commit }, { text, textId }) {
    speechSynthesisChunker.cancel();

    const utter = new SpeechSynthesisUtterance(text);

    utter.lang = 'ru-RU';

    commit('setCurrentTextToSpeech', textId);

    setTimeout(() => {
      speechSynthesisChunker.speakChunkified(utter, {}, () => commit('setCurrentTextToSpeech', null));
    }, 100);
  },

  stopTextToSpeech ({ commit }) {
    speechSynthesisChunker.cancel();
    commit('setCurrentTextToSpeech', null);
  },

  resolveInitialMainContentVariant ({ commit, state }) {
    const variants = state.content.mainContentVariants;
    const { preferredMainContentKind } = localStorageOptions.fetch('lessonOptions');

    const resolvedVariant = variants.find(variant => variant.kind === preferredMainContentKind);

    if (resolvedVariant) {
      commit('setSelectedMainContentVariant', resolvedVariant);
    } else {
      commit('setSelectedMainContentVariant', variants[0]);
    }
  },

  submitAnswer ({ commit, state, rootState }, data) {
    commit('updateHomework', { submitting: true });

    const promise = axios.put(state.homework.submitUrl, {
      answer: data,
      hash_identifier: rootState.user.hashIdentifier,
    });

    homeworkSubmitHandler.handle(promise, { commit });
  },

  submitComment ({ commit, state, rootState }, data) {
    commit('updateHomework', { submitting: true });

    const promise = axios.put(state.homework.submitUrl, {
      comment: data,
      hash_identifier: rootState.user.hashIdentifier,
    });

    homeworkSubmitHandler.handle(promise, { commit });
  },

  submitAnswersRatings ({ commit, state, rootState }, data) {
    commit('updateHomework', { submitting: true });

    const promise = axios.put(state.homework.submitUrl, {
      ratings: data,
      hash_identifier: rootState.user.hashIdentifier,
    });

    homeworkSubmitHandler.handle(promise, { commit });
  },

  submitCommentsRatings ({ commit, state, rootState }, data) {
    commit('updateHomework', { submitting: true });

    const promise = axios.put(state.homework.submitUrl, {
      ratings: data,
      hash_identifier: rootState.user.hashIdentifier,
    });

    homeworkSubmitHandler.handle(promise, { commit });
  },

  fileComplaint ({ commit, state, getters }, data) {
    commit('setComplaintSubmitting', true);

    const complaintType = getters.complaintType;
    const complainable = getters.complainable;

    return axios.post(state.complaint.submitUrl, {
      complaint: {
        ...data,
        lesson_id: state.id,
        complainable_id: complainable.id,
        complaint_type: complaintType,
        system: complainable.system,
      },
    }).then(() => {
      commit('setComplainableComplained', complainable);
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      commit('setComplaintSubmitting', false);
    });
  },

  selectMainContentVariant ({ commit }, variant) {
    localStorageOptions.set('lessonOptions', { preferredMainContentKind: variant.kind });

    commit('setSelectedMainContentVariant', variant);
  },

  selectSpellcheck ({ commit }, value) {
    localStorageOptions.set('lessonOptions', { spellcheck: value });

    commit('setSpellcheck', value);
  },

  purchaseLessonService ({ commit, dispatch }, url) {
    return axios.post(url).then((response) => {
      commit('changeVitn', response.data.vitn, { root: true });
      commit('updateContent', response.data.content);
      dispatch('resolveInitialMainContentVariant');
    }).catch((error) => {
      console.log(error);
    });
  },

  checkStatusChanged ({ commit, state }) {
    const { statusUrl } = state;

    return axios.get(statusUrl).then(({ data: actualStatus }) => {
      const { statusChanged, status } = state;

      if (!statusChanged && status !== actualStatus) {
        commit('setStatusChanged', true);
      }
    }).catch(error => {
      console.log(error);
    });
  },

  logout () {
    return axios.delete(logoutPath).then((response) => {
      $('meta[name="csrf-token"]').attr('content', response.headers['x-csrf-token']);
    });
  },

  relogin ({ commit }, params) {
    return axios.post('/login', params).then((response) => {
      $('meta[name="csrf-token"]').attr('content', response.headers['x-csrf-token']);
      commit('setUnauthorized', false);
    }).catch((error) => {
      console.log(error);
    });
  },
};
