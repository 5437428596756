<template lang="pug" functional>
  transition(name="fade")
    .spinner-container(v-show="props.visible")
      .custom-spinner
</template>

<script>
  /*
  * This component will be removed after bootstrap update, because it will have it's own spinner
  * */

  export default {
    props: {
      visible: {
        type: Boolean,
        default: false
      }
    }
  };
</script>

<style scoped lang="scss">
  @keyframes custom-spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .spinner-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .custom-spinner {
    border: 3px solid black;
    opacity: .3;
    z-index: 1;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border-bottom-color: transparent;
    animation: custom-spinner .6s infinite linear;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .fade-enter-active {
    transition-delay: .3s;
  }
</style>
