export default {
  namespaced: true,

  state: {
    newCount: 0,
    notifications: [],
    timestamp: null
  },

  mutations: {
    initialize(state, { notifications, newCount, timestamp }) {
      state.notifications = notifications;
      state.newCount = newCount;
      state.timestamp = timestamp;
    }
  }
};