/*
* This axios instance is configured as default, but then also has request interceptors
* to transform any json keys to snake_case on request, to camelCase on response for quality of life purposes.
*
* In future this behaviour is intended to become the default.
* But since there is a lot of code to rewrite and check, it will be done later.
* I hope.
* */


import axios from 'axios';
import { applyConfig } from '@shared/axios-config-default';
import { applyConfig as applyKeysConfig } from '@shared/axios-config-transform-keys';

const transformingKeysAxios = axios.create();
applyConfig(transformingKeysAxios);
applyKeysConfig(transformingKeysAxios);

// This assignment will be removed later. Do not depend on it.
window.axios = transformingKeysAxios;

const { get, post, put, patch } = transformingKeysAxios;
export { get, post, put, patch };
export default transformingKeysAxios;
