import { computed, ref } from '@vue/composition-api';

const dataRefs = {};

export default function reactiveLocalstorageValue (key) {
  dataRefs[key] = dataRefs[key] || ref(null);

  return computed({
    get () {
      const jsonValue = dataRefs[key].value || localStorage.getItem(key);

      return JSON.parse(jsonValue);
    },

    set (value) {
      const jsonValue = JSON.stringify(value);

      dataRefs[key].value = jsonValue;
      localStorage.setItem(key, jsonValue);
    },
  });
}
