import Vue from 'vue';
import App from './App';
import { router } from './router';

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('schedule_app');

  if (!container) return;

  const app = new Vue({
    router,

    template: '<App/>',

    components: {
      App,
    },
  });

  app.$mount(container);
});
