<template lang="pug">
  a.mr-0.nav-link(href="#" v-if="user" @click.prevent="handleAuthenticatedClick")
    PopoverWrapper(title="Добро пожаловать!" :html="true" ref="popover")
      template(slot="popoverContent")
        | Нажмите здесь для быстрого доступа к Вашему личному кабинету.
        .text-center
          a.btn.btn-sm.btn-orange-gradient(href="#" @click.prevent="resolvePopover" class="mt-2") ОК

      .personal-icon-wrapper(:class="{ open: sidebarOpen('personal') }")
        UserAvatar(:user="user")
        .personal-icon-overlay
          i.fa.fa-user-circle-o
  a#sign_in_btn.ml-2.btn.btn-outline-porcelain.rounded-pill(href="#" v-else data-toggle="modal" data-target="#login_registration_modal" data-sub-target="#login_tab")
    | Войти
</template>

<script>
  import { mapState, mapMutations, mapGetters } from 'vuex';
  import { post } from '@shared/default-axios';

  import { resolvePersonalIconPopoverPath } from '@app_shared/paths.js.erb';

  export default {
    computed: {
      ...mapState(['user']),
      ...mapGetters(['sidebarOpen']),
    },

    methods: {
      ...mapMutations(['openSidebar', 'setPersonalIconPopoverSeen']),

      handleAuthenticatedClick () {
        this.openSidebar('personal');

        if (this.user.showPersonalIconPopover)
          this.resolvePopover();
      },

      resolvePopover () {
        this.$refs.popover.hide();

        this.setPersonalIconPopoverSeen();
        post(resolvePersonalIconPopoverPath);
      },
    },

    mounted () {
      if (!this.user || !this.user.showPersonalIconPopover) return;

      this.$nextTick(() => {
        this.$refs.popover.show();
      });
    },
  };
</script>

<style scoped lang="scss">
  a {
    position: relative;
  }

  .badge {
    font-size: 10px;
    position: absolute;
    right: 0px;
    top: 30px;
  }

  .personal-icon-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    -webkit-transform: rotate(0);

    .user-avatar {
      border-radius: 0 !important;
      transition: opacity 0s;
    }

    .personal-icon-overlay {
      position: absolute;
      left: -3%;
      top: -3%;
      width: 106%;
      height: 106%;
      line-height: 40px;
      background: $boulder  ;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50% 0 0 50%;
      transform: translate3d(46px, 0, 0);
      transition: transform .2s ease-out;

      i.fa {
        font-size: 24px;
      }
    }

    &:hover, &.open {
      .personal-icon-overlay {
        transform: translate3d(0, 0, 0);
      }

      .user-avatar {
        opacity: 0;
        transition: opacity 0s .15s;
      }
    }
  }
</style>
