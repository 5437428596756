<template lang="pug">
  div(v-if="to === null")
    slot
  RouterLink(v-else-if="typeof to === 'object'" :to="to" @click="$emit('click')")
    slot
  a(v-else-if="typeof to === 'string'" :href="to" @click="$emit('click')")
    slot
</template>

<script>
  // This component uses router link or plain 'a' tag dependent on type of 'to' prop.
  // It is used to reuse other components within and outside of applications with vue router
  export default {
    props: {
      to: {
        validator: value => ['string', 'object'].includes(typeof value) || value === null
      }
    }
  };
</script>