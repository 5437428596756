import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default function(ref) {
  return {
    props: {
      disableBodyScroll: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        $_disableScrollTarget: null
      }
    },

    mounted() {
      if (!this.disableBodyScroll) return;

      if (ref && this.$refs[ref]) {
        this.$_disableScrollTarget = this.$refs[ref];
      } else {
        this.$_disableScrollTarget = this.$el;
      }

      disableBodyScroll(this.$_disableScrollTarget);
    },

    beforeDestroy() {
      if (!this.disableBodyScroll) return;

      enableBodyScroll(this.$_disableScrollTarget);
    }
  };
};