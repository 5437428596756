<template>
  <div class="sidebar-bottom-buttons">
    <a :href="notificationsPath" class="btn-green-gradient">Смотреть все</a>
  </div>
</template>

<script>
  import gradientPickerMixin from '../../shared/mixins/gradient-picker-mixin';

  import { notificationsPath } from '@app_shared/paths.js.erb';

  export default {
    mixins: [gradientPickerMixin],

    created() {
      this.notificationsPath = notificationsPath;
    }
  };
</script>

<style scoped lang="scss">
  .sidebar-bottom-buttons {
    background-color: white;

    a {
      font-size: 14px;
      display: block;
      text-align: center;
      font-weight: 500;
      padding: 6px;
    }
  }
</style>