import Vue from 'vue';

import { getSymbolFromIso } from '@app_shared/ecommerce.js.erb';

Vue.filter('capitalize', function (value) {
  if (!value) return '';

  value = value.toString();

  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('money', ({ cents, currencyIso }) => `${ (cents / 100) } ${getSymbolFromIso(currencyIso)}`);