<template lang="pug">
  .star-wrapper
    span.star.fa.p-1(
      :class="starClass"
    )
    span.star-value(
      :class="textClass"
    ) {{ position }}
</template>

<script>
  import { computed } from '@vue/composition-api';

  export default {
    props: {
      position: {
        type: Number,
        required: true,
      },

      value: {
        type: Number,
        default: 0,
      },
    },

    setup (props) {
      return {

        textClass: computed(() => {
          const { position, value } = props;

          if (position === value) {
            return 'text-java font-weight-bold';
          } else {
            return 'text-alto';
          }
        }),

        starClass: computed(() => {
          const { position, value } = props;

          if (position > value) {
            return 'fa-star-o text-alto';
          } else {
            return 'fa-star text-java';
          }
        }),
      };
    },
  };
</script>

<style lang="scss" scoped>
  .star-wrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    cursor: pointer;

    &:hover {
      .star {
        transform: scale(1.2);
      }
    }
  }

  .star-value {
    font-weight: 500;
  }

  .star {
    transition: transform 0.1s, color 0.25s;

    font-size: 1.2rem;

    @include media-breakpoint-up(md) {
      font-size: 2rem;
    }
  }
</style>
