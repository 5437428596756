export default {
  data: function() {
    return {
      $_gradientPicker_index: 0,
      $_gradientPicker_classes: ['gradient-deep-cerulean-to-robins-egg-blue', 'gradient-venice-blue-to-robins-egg-blue', 'gradient-royal-purple-to-boston-blue', 'gradient-rhino-to-royal-purple', 'gradient-piper-to-tree-poppy', 'gradient-fuel-yellow-to-piper'],
      $_gradientPicker_hover: false
    };
  },

  methods: {
    $_gradientPicker_keyHandler(event) {
      if (!this.$data.$_gradientPicker_hover) return;

      switch (event.keyCode) {
        case 39: {
          this.$_gradientPicker_incrementGradientIndex();
          break;
        }

        case 37: {
          this.$_gradientPicker_decrementGradientIndex();
          break;
        }
      }
    },

    $_gradientPicker_incrementGradientIndex() {
      this.$data.$_gradientPicker_index++;

      if (this.$data.$_gradientPicker_index > (this.$data.$_gradientPicker_classes.length - 1)) {
        this.$data.$_gradientPicker_index = 0;
      }
    },

    $_gradientPicker_decrementGradientIndex() {
      this.$data.$_gradientPicker_index--;

      if (this.$data.$_gradientPicker_index < 0) {
        this.$data.$_gradientPicker_index = this.$data.$_gradientPicker_classes.length - 1;
      }
    },

    $_gradientPicker_hoverHandler(event) {
      if (event.type === 'mouseenter')
        this.$data.$_gradientPicker_hover = true;
      else if (event.type === 'mouseleave')
        this.$data.$_gradientPicker_hover = false;
    }
  },

  computed: {
    $_gradientPicker_selected() {
      return this.$data.$_gradientPicker_classes[this.$data.$_gradientPicker_index];
    }
  },

  mounted() {
    this.$_gradientPicker_hoverHandler = this.$_gradientPicker_hoverHandler.bind(this);

    window.addEventListener('keydown', this.$_gradientPicker_keyHandler);
    this.$el.addEventListener('mouseenter', this.$_gradientPicker_hoverHandler);
    this.$el.addEventListener('mouseleave', this.$_gradientPicker_hoverHandler);
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.$_gradientPicker_keyHandler);
    this.$el.removeEventListener('mouseenter', this.$_gradientPicker_hoverHandler);
    this.$el.removeEventListener('mousemouseleave', this.$_gradientPicker_hoverHandler);
  }
};