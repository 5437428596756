const applyConfig = (axiosInstance) => {
  // Default JSON headers
  axiosInstance.defaults.headers.common['Accept'] = 'application/json';
  axiosInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

  // CSRF token handling via request interceptor
  axiosInstance.interceptors.request.use((config) => {
    const metaTag = document.querySelector('meta[name="csrf-token"]');
    if (metaTag) {
      config.headers.common['X-CSRF-Token'] = metaTag.getAttribute('content');
    }
    return config;
  });

  // Flash messages handling via response interceptor
  axiosInstance.interceptors.response.use((response) => {
    window.flashApp.flashHandler();
    return response;
  }, (error) => {
    window.flashApp.flashHandler();
    return Promise.reject(error);
  });
};

export {
  applyConfig,
};