<template>
  <li :class="['feed-item', item.color, { new: i < newCount }]">
    <div class="feed-notification-icon" v-html="item.icon">
    </div>

    <div class="feed-notification-content">
      <small class="feed-notification-timestamp">
        {{ item.createdAt }}
      </small>

      <div class="feed-notification-title">
        {{ item.title }}
      </div>

      <div class="feed-notification-body" v-html="item.body">

      </div>
    </div>
  </li>
</template>

<script>
  export default {
    props: {
      item: {
        type: Object,
        required: true
      },

      i: {
        type: Number,
        required: true
      },

      newCount: {
        type: Number,
        required: true
      }
    }
  };
</script>

<style scoped lang="scss">

</style>
