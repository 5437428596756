export default function initServerTimeChannel ({ onReceived, onFailure }) {
  // eslint-disable-next-line no-undef
  return App.cable.subscriptions.create({
    channel: 'ServerTimeChannel',
  }, {
    received: function (data) {
      onReceived(data.current_time);
    },

    disconnected: function () {
      onFailure();
    },

    rejected: function () {
      onFailure();
    }
  });
};