<template>
  <div :class="['scroll-wrapper', { 'should-flex': shouldFlex, scrollable: scrollable }]" ref="scrollWrapper">
    <div :class="['shadow-top', { opaque: !scrollableUp }]"></div>

    <div class="scrollable-area" ref="scrollableArea" :style="{ 'max-height': maxHeight }">
      <slot></slot>
    </div>

    <transition name="fade">
      <div :class="['shadow-bottom', { opaque: !scrollableDown }]"></div>
    </transition>
  </div>
</template>

<script>
  import scrollDisablerMixin from '@app_shared/mixins/scroll-disabler-mixin';

  export default {
    mixins: [scrollDisablerMixin('scrollableArea')],

    props: {
      dynamicFlex: {
        type: Boolean,
        default: false,
      },

      maxHeight: {
        type: [Number, String],
        default: 'auto',
      },
    },

    data() {
      return {
        scrollableDown: false,
        scrollableUp: false,
        shouldFlex: true,
      };
    },

    computed: {
      scrollable() {
        return this.scrollableDown || this.scrollableUp;
      },
    },

    mounted () {
      if (this.dynamicFlex) { this.handleFlex(); }

      this.handleScroll();
    },

    beforeDestroy () {
      window.removeEventListener('resize', this.flexHandler);

      window.removeEventListener('resize', this.scrollableHandler);
      this.$refs.scrollableArea.removeEventListener('scroll', this.scrollableHandler);
    },

    methods: {
      scrollableHandler() {
        const scrollableArea = this.$refs.scrollableArea;

        this.scrollableUp = !(scrollableArea.scrollTop <= 0);
        this.scrollableDown = !(scrollableArea.clientHeight + scrollableArea.scrollTop >= scrollableArea.scrollHeight);
      },

      flexHandler() {
        const scrollWrapper = this.$refs.scrollWrapper;
        const scrollableArea = this.$refs.scrollableArea;

        if (!this.shouldFlex && (scrollableArea.offsetHeight > scrollWrapper.offsetHeight)) {
          this.shouldFlex = true;
        } else if (this.shouldFlex && (scrollableArea.offsetHeight < scrollWrapper.offsetHeight)) {
          this.shouldFlex = false;
        }
      },

      handleFlex() {
        this.flexHandler = this.flexHandler.bind(this);

        window.addEventListener('resize', this.flexHandler);
        this.$nextTick(this.flexHandler);
      },

      handleScroll() {
        this.scrollableHandler = this.scrollableHandler.bind(this);

        window.addEventListener('resize', this.scrollableHandler);
        this.$refs.scrollableArea.addEventListener('scroll', this.scrollableHandler);
        this.$nextTick(this.scrollableHandler);
      },

      resetScroll() {
        this.$refs.scrollableArea.scrollTop = 0;
      },
    },
  };
</script>

<style scoped lang="scss">
  .scroll-wrapper {
    cursor: default;
    min-height: 0;
    position: relative;
    overflow: hidden;
    max-height: 100%;

    @include media-breakpoint-down(xs) {
      margin-bottom: 0;
    }

    &.should-flex {
      flex: 1;
    }

    .scrollable-area {
      max-height: 100%;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  // Shadow
  .shadow-top, .shadow-bottom {
    height: 1px;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    transition: opacity .2s;

    &.opaque {
      opacity: 0;
    }
  }

  .shadow-top {
    top: 0;
    margin-top: -1px;
  }

  .shadow-bottom {
    bottom: 0;
    margin-bottom: -1px;
  }
</style>