import VueRouter from 'vue-router';
import { basePath } from './base-path.js.erb';
import Show from './pages/Show';

const router = new VueRouter({
  mode: 'history',

  base: basePath,

  routes: [
    {
      path: '/',
      name: 'show',
      component: Show,
    },
  ],
});

export {
  router,
};
