<template lang="pug">
  .container.mt-3
    .row.mb-2.mb-md-3
      .col
        PillButton.mr-2.mb-2.active.d-block.d-md-none(
          data-toggle="collapse"
          data-target="#schedule_tags"
        )
          template(v-if="activeTag") {{activeTag}}
          template(v-else) Все направления
          i.fa.fa-angle-down.ml-2
        #schedule_tags.collapse
          PillButton.mr-2.mb-2(
            @click="selectTag(null)"
            :class="{ active: !activeTag }"
          ) Все направления
          PillButton.mr-2.mb-2(
            v-for="tag in tags"
            :key="tag"
            :class="{ active: activeTag === tag }"
            @click="selectTag(tag)"
          ) {{tag}}

    .row.mb-2.mb-md-3
      .col
        PillButton.mr-2.mb-2.active(
          data-toggle="collapse"
          data-target="#schedule_authors"
        )
          | Автор:#{' '}
          template(v-if="activeAuthor") {{activeAuthor}}
          template(v-else) все
          i.fa.fa-angle-down.ml-2
        #schedule_authors.collapse
          PillButton.mr-2.mb-2(
            @click="selectAuthor(null)"
            :class="{ active: !activeAuthor }"
          ) Все авторы
          PillButton.mr-2.mb-2(
            v-for="author in authors"
            :key="author"
            :class="{ active: activeAuthor === author }"
            @click="selectAuthor(author)"
          ) {{author}}

    .row.mb-3
      .col-12
        h1 {{ activeTag === null ? 'Все направления' : `Направление: ${activeTag}` }}

      template(v-if="limitedFilteredItems.length")
        .col-12.col-md-6.col-lg-4.my-3(
          v-for="item in limitedFilteredItems"
          :key="item.title"
          v-html="item.html"
        )
      .col-12(v-else)
        .text-muted.text-center
          | Ничего не найдено. Попробуйте#{' '}
          a(
            href="#"
            @click.prevent="resetFilters"
          ) убрать фильтры
          | .
    .row.my-4
      .col
        .text-center
          PillButton.btn-lg.px-5(
            v-if="limit < filteredItems.length"
            @click="limit += 12"
          )
            | Показать ещё курсы
</template>

<script>
  import { ref, computed, watch } from '@vue/composition-api';
  import PillButton from './PillButton';

  const DEFAULT_LIMIT = 12;

  export default {
    components: {
      PillButton,
    },

    props: {
      items: {
        type: Array,
        required: true,
      },

      authors: {
        type: Array,
        required: true,
      },

      tags: {
        type: Array,
        required: true,
      },
    },

    setup (props, context) {
      const router = context.root.$router;
      const route = context.root.$route;

      const activeAuthor = ref(null);
      const activeTag = ref(null);

      const limit = ref(DEFAULT_LIMIT);

      selectTag(route.query.tag || null);
      selectAuthor(route.query.author || null);

      watch([activeAuthor, activeTag], ([author, tag]) => {
        const query = {};

        if (author) query.author = author;
        if (tag) query.tag = tag;

        router.replace({ query });
      });

      const filteredItems = computed(() => {
        let items = props.items;

        if (activeAuthor.value) {
          items = items.filter(item => item.author === activeAuthor.value);
        }

        if (activeTag.value) {
          items = items.filter(item => item.tags.includes(activeTag.value));
        }

        return items;
      });

      const limitedFilteredItems = computed(() => {
        return filteredItems.value.slice(0, limit.value);
      });

      function resetLimit () {
        limit.value = DEFAULT_LIMIT;
      }

      function selectTag (tag) {
        activeTag.value =
          activeTag.value === tag || !props.tags.includes(tag)
            ? null
            : tag;

        resetLimit();
      }

      function selectAuthor (author) {
        activeAuthor.value =
        activeAuthor.value === author || !props.authors.includes(author)
            ? null
            : author;


        resetLimit();
      }

      return {
        filteredItems,
        limitedFilteredItems,

        limit,

        activeAuthor,

        activeTag,

        selectTag,

        selectAuthor,

        resetFilters () {
          activeTag.value = null;
          activeAuthor.value = null;
          resetLimit();
        },
      };
    },
  };
</script>

<style scoped lang="scss">
  #schedule_tags {
    @include media-breakpoint-up(md) {
      display: block !important;
    }
  }

  button[data-toggle="collapse"] {
    i.fa-angle-down {
      transition: transform 0.1s ease-in-out;
    }

    &[aria-expanded="true"] i.fa-angle-down {
      transform: rotate(180deg);
    }
  }
</style>
