/* global $ */

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('personal_settings');

  if (!container) return;

  manageAvatarUpload();
  manageUserSettingSwitches();
});

function manageAvatarUpload () {
  const input = document.getElementById('new_avatar');
  const spinnerContainer = input.closest('.avatar-form-container').querySelector('.spinner-container');

  let uploading = false;

  input.addEventListener('change', () => {
    const formData = new FormData();
    const file = input.files[0];

    spinnerContainer.classList.remove('invisible');
    uploading = true;

    formData.append('user[avatar]', file, file.name);

    // Using $.ajax because axios doesn't know how to handle .js response
    $.ajax({
      type: 'POST',
      url: input.dataset.url,
      data: formData,
      processData: false,
      contentType: false,
      complete: function () {
        uploading = false;
        spinnerContainer.classList.add('invisible');
        manageAvatarUpload();
      },
    });
  });

  const avatarBtns = document.getElementsByClassName('avatar-btn');

  avatarBtns.forEach(btn => {
    btn.addEventListener('click', event => {
      if (uploading) event.preventDefault();
    });
  });
}

function manageUserSettingSwitches () {
  const switches = document.getElementsByClassName('user-setting-switch');

  switches.forEach(switchInput => {
    switchInput.addEventListener('change', () => {
      switchInput.disabled = true;

      const name = switchInput.getAttribute('name');
      const value = switchInput.checked;
      const spinnerBorder = switchInput
        .closest('.switch-container')
        .querySelector('.spinner-border');

      const spinnerTimeout = setTimeout(() => {
        spinnerBorder.classList.remove('invisible');
      }, 300);

      $.ajax({
        type: 'PATCH',
        url: switchInput.dataset.url,
        data: { switch: { name, value } },
        complete: () => {
          clearTimeout(spinnerTimeout);
          spinnerBorder.classList.add('invisible');
          switchInput.disabled = false;
        },
      });
    });
  });
}

