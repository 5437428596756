<template>
  <transition name="height"
              mode="out-in"
              @before-leave="beforeLeave"
              @enter="enter"
              @after-enter="afterEnter"
  >
    <slot></slot>
  </transition>
</template>

<script>
  export default {
    name: 'TransitionHeight',

    data() {
      return {
        heightWas: 'auto',
        height: 'auto',
        active: false
      };
    },

    methods: {
      beforeLeave(element) {
        // element.classList.add('overflow-hidden');
        this.heightWas = getComputedStyle(element).height;
        this.$emit('start');
      },

      enter(element, done) {
        const width = getComputedStyle(element).width;

        element.style.width = width;
        element.style.visibility = 'hidden';
        element.style.position = 'absolute';
        element.style.height = 'auto';

        this.height = getComputedStyle(element).height;

        element.style.width = null;
        element.style.position = null;
        element.style.visibility = null;
        element.style.height = this.heightWas;

        setTimeout(() => {
          element.style.height = this.height;

          const listener = (event) => {
            if (event.target !== element || event.propertyName !== 'height') return;
            element.removeEventListener('transitionend', listener);
            done();
          };

          element.addEventListener('transitionend', listener);
        });
      },

      afterEnter (element) {
        element.classList.remove('overflow-hidden');
        element.style.height = 'auto';
        this.$emit('end');
      }
    }
  };
</script>

<style scoped>
  .height-enter-active {
    transition: height .5s ease-out;
  }

  * {
    will-change: height;
    transform: translateZ(0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
</style>