/**
 * Loosely (very loosely) based on https://gist.github.com/woollsta/2d146f13878a301b36d7
 */

const speechSynthesisChunker = {
  cancelling: false,

  utterances: [],

  speakChunkified (sourceUtterance, { chunkLength = 200 }, callback) {
    const text = sourceUtterance.text;

    const regex = new RegExp('^[\\s\\S]{' + Math.floor(chunkLength / 2) + ',' + chunkLength + '}[.!?,]{1}|^[\\s\\S]{1,' + chunkLength + '}$|^[\\s\\S]{1,' + chunkLength + '} ');

    const chunks = [];

    let sourceText = text;
    while (sourceText.length) {
      let matched = sourceText.match(regex);

      if (!matched || !matched[0]) {
        break;
      } else {
        chunks.push(matched[0]);
        sourceText = sourceText.substring(matched[0].length);
      }
    }

    const utterances = chunks.map(chunk => {
      const utterance = new SpeechSynthesisUtterance(chunk);

      return utterance;
    });

    utterances.forEach(utterance => {
      utterance.lang = sourceUtterance.lang;
      utterance.addEventListener('end', boundSpeakNext);
    });

    if (utterances.length) {
      utterances[utterances.length - 1].addEventListener('end', callback);
    }

    this.utterances = utterances;
    this.speakNext();
  },

  speakNext () {
    const utterance = this.utterances.shift();

    if (utterance) {
      console.log(utterance); //IMPORTANT!! Do not remove: Logging the object out fixes some onend firing issues.
      //placing the speak invocation inside a callback fixes ordering and onend issues.
      setTimeout(() => {
        speechSynthesis.speak(utterance);
      }, 0);
    }
  },

  cancel () {
    this.utterances.forEach(utterance => utterance.removeEventListener('end', boundSpeakNext));
    this.utterances = [];
    speechSynthesis.cancel();
  }
};

const boundSpeakNext = speechSynthesisChunker.speakNext.bind(speechSynthesisChunker);

export default speechSynthesisChunker;