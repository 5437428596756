<template>
  <SidebarWrapper :position="position">
    <SidebarMainHeader>
      <div class="media">
        <a :href="personalSettingsPath" title="К настройкам">
          <UserAvatar class="user-avatar-lg mr-3" :user="user"/>
        </a>

        <div class="media-body align-self-center">
          <a :href="personalSettingsPath" title="К настройкам" class="d-block header-link">
            {{ user.name }}
          </a>

          <a :href="vitnPath" title="К премиальным пунктам" class="d-block header-link">
            {{ user.vitn }} пункт.
          </a>
        </div>
      </div>
    </SidebarMainHeader>

    <ScrollWrapper :disable-body-scroll="true">
      <ul class="list-unstyled sidebar-body">
        <NavItem v-for="(item, index) in navItems" :key="index" :item="item" />

        <NavItem :item="logoutItem" method="delete" class="logout" />
      </ul>
    </ScrollWrapper>
  </SidebarWrapper>
</template>

<script>
  import SidebarWrapper from '../shared/SidebarWrapper';
  import SidebarMainHeader from '../shared/SidebarMainHeader';
  import NavItem from '../shared/NavItem';
  import ScrollWrapper from '@app_shared/components/ScrollWrapper';

  import sidebarWrapperMixin from '../shared/mixins/sidebar-wrapper-mixin';
  import scrollDisablerMixin from '@app_shared/mixins/scroll-disabler-mixin';

  import { mapState } from 'vuex';

  import { logoutPath, personalSettingsPath, vitnPath } from '@app_shared/paths.js.erb';

  export default {
    mixins: [sidebarWrapperMixin, scrollDisablerMixin('header')],

    components: {
      SidebarWrapper,
      ScrollWrapper,
      SidebarMainHeader,
      NavItem,
    },

    computed: {
      ...mapState([
        'user',
        'navItems',
        'logoutPath',
      ]),

      logoutItem () {
        return {
          href: logoutPath,
          title: '<i class="fa fa-fw fa-sign-out"></i> Выход',
        };
      },
    },

    created () {
      this.personalSettingsPath = personalSettingsPath;
      this.vitnPath = vitnPath;
    },
  };
</script>

<style scoped lang="scss">
  @mixin personal-item-border {
    border-bottom: 1px solid #eaeaeb;
  }

  .header-link {
    color: white;

    &:hover {
      opacity: .85;
    }

    &:active, &:focus {
      opacity: 1;
      text-shadow: 0 0 1px rgba(255, 255, 255, 0.7);
    }
  }

  .sidebar-body li a {
    padding: 0 1.4rem;
    width: 100%;
  }

  .sidebar-body {
    > li {
      > a {
        display: block;

        color: $scorpion;

        i {
          color: $oslo-gray;
          margin: 0 .5rem;
        }

        span {
          display: block;
          padding: .8rem 0;
          @include personal-item-border;
        }

        &[aria-expanded="true"] {
          span {
            border-bottom-color: transparent;
          }
        }
      }

      ul {
        background: $porcelain;
        box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.3) inset;

        li, a, span { display: block; }

        > li {
          > a {
            color: $scorpion;

            span {
              padding: .8rem 0;
            }
          }
        }
      }

      &.logout {
        > a span {
          border-bottom: none;
          text-align: center;
        }
      }
    }
  }
</style>
