import reactiveLocalstorageValue from '@shared/composition-utils/reactive-localstorage-value';

const SUPPORTED_CURRENCIES = [{"isoCode":"EUR","symbol":"€"},{"isoCode":"RUB","symbol":"₽"}];

const BASE_CURRENCY_ISO = 'EUR';

const selectedCurrencyIsoRef = reactiveLocalstorageValue('ecommerce_currency');

function getSymbolFromIso (iso) {
  return SUPPORTED_CURRENCIES.find(({ isoCode }) => isoCode === iso).symbol;
}

function getEcommercePrice ({ ecommercePriceTags }) {
  return ecommercePriceTags.find(({ currencyIso }) => currencyIso === selectedCurrencyIsoRef.value);
}

function initalizeSelectedCurrency () {
  if (SUPPORTED_CURRENCIES.some(({ isoCode }) => selectedCurrencyIsoRef.value === isoCode)) return;

  selectedCurrencyIsoRef.value = BASE_CURRENCY_ISO;
}

initalizeSelectedCurrency();

export {
  SUPPORTED_CURRENCIES,
  BASE_CURRENCY_ISO,
  selectedCurrencyIsoRef,
  getEcommercePrice,
  getSymbolFromIso,
};
