import { snakeCase } from 'lodash';
import { CancelToken, isCancel } from 'axios';

/*
* Copypasta from vuex helper js (sadly it does not export those)
* */
function normalizeMap (map) {
  return Array.isArray(map)
    ? map.map(function (key) {
      return ({
        key: key,
        val: key
      });
    })
    : Object.keys(map).map(function (key) {
      return ({
        key: key,
        val: map[key]
      });
    });
}

function normalizeNamespace (fn) {
  return function (namespace, map) {
    if (typeof namespace !== 'string') {
      map = namespace;
      namespace = '';
    } else if (namespace.charAt(namespace.length - 1) !== '/') {
      namespace += '/';
    }
    return fn(namespace, map);
  };
}

function getModuleByNamespace (store, helper, namespace) {
  var module = store._modulesNamespaceMap[namespace];

  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV !== 'production' && !module) {
    console.error(('[vuex] module namespace not found in ' + helper + '(): ' + namespace));
  }
  return module;
}

/*
* Copypasta END
* */


function constructSetterName (key) {
  return snakeCase(`set_${key}`).toUpperCase();
}

/*
* State-to-mutation helper: to reduce boilerplate writing simple atomic mutations for state entries
*
* Example use:
*
* mutations: {
*   ...defineSetters([
*     'name',
*     'email'
*   ])
* }
*
* Now we have mutations 'SET_NAME' and 'SET_EMAIL'.
* */
const defineSetters = (keys) => {
  return keys.reduce((accumulator, key) => {
    const setterName = constructSetterName(key);

    accumulator[setterName] = (state, value) => state[key] = value;
    return accumulator;
  }, {});
};

/*
* Simple state-to-getters helper: to reduce boilerplate writing simple atomic getters from state
*
* Example use:
*
* getters: {
*   ...defineGetters([
*     'name',
*     'email'
*   ])
* }
*
* Now we have getters for 'name' and 'email'
* */
const defineGetters = (keys) => {
  return keys.reduce((accumulator, key) => {
    accumulator[key] = (state) => state[key];
    return accumulator;
  }, {});
};

/*
* Two-way binding helper: to reduce boilerplate writing getters and setters in components
*
* Example use:
*
* computed: {
*   ...mapAccessors('user', [
*     'name',
*     'email'
*   ])
* }
*
* Component now has two-way data binding for user/name and user/email state entries.
* */
const mapAccessors = normalizeNamespace((namespace, accessors) => {
  const response = {};

  normalizeMap(accessors).forEach(accessor => {
    const { key: accessorReturnKey, val: accessorStateKey } = accessor;

    response[accessorReturnKey] = storeAccessor(null, namespace, accessorStateKey);
  });

  return response;
});

/*
* Two-way binding helper for single property
* Returns object with set() and get() methods which internally use store.
* Is intended to be used with vue composition api
*
* Example use:
* const userName = computed(mapAccessor(store, 'user', 'name'))
*
* userName is now a Ref with custom setter and getter mapped to store.user.name state prop and store.commit('user/SET_NAME', value)
* */
const mapAccessor = (store, namespace, accessorStateKey) => {
  return normalizeNamespace((namespace, accessorStateKey) => {
    return storeAccessor(store, namespace, accessorStateKey);
  })(namespace, accessorStateKey);
};


const storeAccessor = (customStore, namespace, accessorStateKey) => {
  return {
    get () {
      const store = customStore || this.$store;
      let { state } = store;

      if (namespace) {
        const module = getModuleByNamespace(store, 'mapState', namespace);

        if (!module) return;
        state = module.context.state;
      }

      return state[accessorStateKey];
    },

    set () {
      const store = customStore || this.$store;
      const args = [];

      let length = arguments.length;
      while (length--) args[length] = arguments[length];

      let { commit } = store;

      if (namespace) {
        const module = getModuleByNamespace(store, 'mapMutations', namespace);

        if (!module) return;
        commit = module.context.commit;
      }

      return commit.apply(store, [constructSetterName(accessorStateKey)].concat(args));
    }
  };
};

/*
* 1-to-1 mutation to action mapper for vuex store to reduce boilerplate.
*
* Example use:
*
* actions: {
*   ...defineActions([
*     'setUser',
*     'setSettings'
*   ])
* }
*
* Now we have actions which commit SET_USER and SET_SETTINGS mutations
* */
const defineActions = (actions) => {
  const response = {};

  actions.forEach(actionKey => {
    const mutationName = snakeCase(actionKey).toUpperCase();

    response[actionKey] = function ({ commit }, ...args) {
      commit(mutationName, ...args);
    };
  });

  return response;
};

const cancelFunctions = {};
const useCancelToken = (key) => {
  if (cancelFunctions[key]) {
    cancelFunctions[key]();
  }

  const cancelToken = new CancelToken(cancel => cancelFunctions[key] = cancel);
  return cancelToken;
};

/**
 * 1. Wraps axios request promise with commits to SET_%mutationBase%_PENDING 
 * 2. Rescues from axios cancel
 * 
 * 
 * @param {string} mutationBase string base for mutation name
 * @param {function} commit vuex commit function provided from action args
 * @param {Promise} requestPromise 
 * 
 * @returns {Promise} provided request promise
 */
function withPending (mutationBase, commit, requestPromise) {
  if (mutationBase) commit(`SET_${mutationBase}_PENDING`, true);

  let cancelled = false;

  return requestPromise.catch(error => {
    if (isCancel(error))
      cancelled = true;

    throw error;
  }).finally(() => {
    if (!cancelled && mutationBase) commit(`SET_${mutationBase}_PENDING`, false);
  });
}


/*
* Simple error handler which handles request request cancel error but rethrows other.
*
* Reason this exists:
* If we promise-catch cancel error before main '.then', it goes straight to '.then' but without any data from request (cuz it was cancelled).
* So we cannot just bind this to asyncAction function -- we have to attach it to '.catch' after our main '.then's
* but before our general '.catch' (which handles all types of errors)
* */
const cancelledRequestHandler = (error) => {
  if (isCancel(error)) {
    console.log('Request canceled', error.message);
  } else {
    console.error(error);
    throw error;
  }
};


export {
  defineGetters,
  defineSetters,
  defineActions,
  mapAccessor,
  mapAccessors,
  constructSetterName,
  cancelledRequestHandler,
  useCancelToken,
  withPending,
};
