import Vue from 'vue';
import Vuex from 'vuex';

import notificationsModule from './store_modules/notifications-module';
import navigationModule from './store_modules/navigation-module';
import lessonModule from './store_modules/lesson-module';
import serverTimeModule from './store_modules/server-time-module';
import testimonialsModule from './store_modules/testimonials-module';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    notifications: notificationsModule,
    navigation: navigationModule,
    lesson: lessonModule,
    serverTime: serverTimeModule,
    testimonials: testimonialsModule,
  },

  state: {
    initialized: false,

    user: null,
    navItems: null,
    logoutPath: null,
    openedSidebar: null,
    vitnPath: null,
    corporate: null,
    corporatePath: null,
    partner: null,
    partnerPath: null,
    teacher: null,
    teacherPath: null,
    outer: null,
    outerPath: null,
  },

  mutations: {
    initialize (state, { user, navItems, corporate, partner, teacher, outer, internal }) {
      state.user = user;
      state.navItems = navItems;
      state.initialized = true;
      state.corporate = corporate;
      state.partner = partner;
      state.teacher = teacher;
      state.outer = outer;
      state.internal = internal;
    },

    openSidebar (state, type) {
      state.openedSidebar = type;
    },

    closeSidebar (state) {
      state.openedSidebar = null;
    },

    changeVitn (state, vitn) {
      state.user.vitn = vitn;
    },

    setUserAvatarUrl ({ user }, newUrl) {
      user.avatarUrl = newUrl;
    },

    setPersonalIconPopoverSeen ({ user }) {
      user.showPersonalIconPopover = false;
    },
  },

  getters: {
    userSignedIn: ({ user, initialized }) => initialized && user,
    userIsTeacher: ({ user }, { userSignedIn }) => userSignedIn && user.teacher,
    userIsCorporate: ({ user }, { userSignedIn }) => userSignedIn && user.corporate,
    userIsPartner: ({ user }, { userSignedIn }) => userSignedIn && user.partner,
    userIsOuter: ({ user }, { userSignedIn }) => userSignedIn && user.outer,
    userIsInternal: ({ user }, { userSignedIn }) => userSignedIn && user.internal,

    sidebarOpen: ({ openedSidebar }) => (type) => {
      if (type === undefined && openedSidebar !== null) return true;

      return openedSidebar === type;
    },

    anySidebarOpen: ({ openedSidebar }) => openedSidebar !== null,

    authenticated: ({ user }) => user !== null,
  },
});

window.vueStore = store;

export default store;
