<template lang="pug">
  a.nav-link(:href="vitnPath")
    i.fa.fa-diamond.mr-1
    span.d-inline-block.vitn-count {{user ? user.vitn : 0}}
</template>

<script>
  import { mapState } from 'vuex';

  import { vitnPath } from '@app_shared/paths.js.erb';

  export default {
    computed: {
      ...mapState(['user']),
    },

    created () {
      this.vitnPath = vitnPath;
    },
  };
</script>

<style scoped>
  i {
    font-size: 18px !important;
    display: inline-block;
  }

  .vitn-count {
    font-size: 18px;
  }
</style>
