<template lang="pug">
  ScheduleList(
    v-if="scheduleRequest.result"
    :items="data.items"
    :authors="data.authors"
    :tags="data.activeTags"
  )
  .text-center.my-5(v-else-if="scheduleRequest.pending")
    .spinner-border.spinner-border
  .container(v-else-if="scheduleRequest.error"): .row: .col: .my-5.alert.alert-danger
    | Не удалось загрузить список курсов. Код ошибки: {{scheduleRequest.error.response.status}}
</template>

<script>
  import { computed } from '@vue/composition-api';
  import { get } from '@shared/transforming-keys-axios';
  import { basePath } from '../base-path.js.erb';
  import { usePromise } from '@shared/composition-utils/use-promise';
  import ScheduleList from '../components/ScheduleList';

  export default {
    components: {
      ScheduleList,
    },

    setup () {
      const scheduleRequest = useFetchSchedule();

      scheduleRequest.execute();

      return {
        scheduleRequest,
        data: computed(() => scheduleRequest?.result?.data),
      };
    },
  };

  function useFetchSchedule () {
    return usePromise(() => get(basePath));
  }
</script>
