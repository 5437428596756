<template lang="pug">
  img.vue__form-oauth-icon(:src="src" :class="{ active: active }")
</template>

<script>
  import assetPaths from '../asset_paths.js.erb';

  const typeToSrcMap = {
    facebook: 'auth_social_icons/facebook.svg',
    google_oauth2: 'auth_social_icons/google_oauth2.svg',
    vkontakte: 'auth_social_icons/vkontakte.svg',
    yandex: 'auth_social_icons/yandex.svg'
  };

  export default {
    name: 'OauthIcon',

    props: {
      type: {
        type: String,
        required: true,
        validator: (value) => Object.keys(typeToSrcMap).includes(value)
      },

      active: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      src() {
        return assetPaths[typeToSrcMap[this.type]];
      }
    }
  };
</script>

<style scoped lang="scss">
  img.vue__form-oauth-icon {
    opacity: .3;
    border-radius: 50%;
    padding: 4px;
    box-shadow: 0 0 5px $silver-chalice;
    background-color: $white;
    display: block;
    width: 30px;
    height: 30px;
    transition: all .2s;

    &:hover, &.active {
      box-shadow: 0 0 5px $silver-chalice, 0 0 0 2px $java;
    }

    &:hover {
      opacity: .7;
    }

    &.active {
      opacity: 1;
      box-shadow: 0 0 5px $silver-chalice, 0 0 0 2px $java;
    }
  }
</style>