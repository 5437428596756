import '../application/navigation-menu';
import '../application/personal_settings';

import '@shared/default-axios';
import '@app_shared/moment-config';
import '@app/client_feedback';
import '@app/schedule';
import '@app/main_page_swiper';
// Vue imports START
import Vue from 'vue';
import '@shared/vue-use-composition-api';
import '@shared/vue-filters';

import '@app/video_plyr';

import VueRouter from 'vue-router';
import VueMq from 'vue-mq';
import store from '@app_shared/store.js';

import VueHooks from '@u3u/vue-hooks';

import VueTour from 'vue-tour';
import '@app_shared/styles/vue-tour.scss';

import VuePaginate from 'vue-paginate';
import VueSocialSharing from 'vue-social-sharing';

import NavIconsApp from '../application/nav_icons/app.vue';
import SidebarsApp from '../application/sidebars/app.vue';

import TooltipWrapper from '@shared/components/TooltipWrapper';
import OauthIcon from '@shared/components/OauthIcon';
import OauthLinks from '@shared/components/OauthLinks';

import BaseAlert from '@app_shared/components/BaseAlert';
import BaseModal from '@app_shared/components/BaseModal';
import FaStack from '@app_shared/components/FaStack';
import IsomorphicLink from '@app_shared/components/IsomorphicLink';
import ProgressDot from '@app_shared/components/ProgressDot';
import PopoverWrapper from '@app_shared/components/PopoverWrapper';
import PartialCollapse from '@app_shared/components/PartialCollapse';
import ScrollWrapper from '@app_shared/components/ScrollWrapper';
import TransitionExpand from '@app_shared/components/TransitionExpand';
import TransitionHeight from '@app_shared/components/TransitionHeight';
import UserAvatar from '@app_shared/components/UserAvatar';
import SpinnerOverlay from '@app_shared/components/SpinnerOverlay';

import {
  ButtonPlugin,
  CardPlugin,
  SpinnerPlugin,
  FormSelectPlugin,
  PaginationNavPlugin,
  TabsPlugin,
  DropdownPlugin,
  TablePlugin,
  EmbedPlugin,
  ModalPlugin,
  ImagePlugin,
} from 'bootstrap-vue';
// Vue imports END

// Fix for iOS (primarily) bug with range inputs.
// Aaand due to some bug in mobile Safari I had to make custom changes in order for it to work.
// https://github.com/sampotts/rangetouch/issues/14

Vue.use(VueRouter);
Vue.use(VueHooks);
Vue.use(VueTour);
Vue.use(VuePaginate);
Vue.use(VueSocialSharing);
Vue.use(VueMq, {
  breakpoints: {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1400,
  },
});

Vue.component('PartialCollapse', PartialCollapse);
Vue.component('TransitionExpand', TransitionExpand);
Vue.component('TransitionHeight', TransitionHeight);
Vue.component('BaseAlert', BaseAlert);
Vue.component('BaseModal', BaseModal);
Vue.component('IsomorphicLink', IsomorphicLink);
Vue.component('ProgressDot', ProgressDot);
Vue.component('ScrollWrapper', ScrollWrapper);
Vue.component('TooltipWrapper', TooltipWrapper);
Vue.component('FaStack', FaStack);
Vue.component('UserAvatar', UserAvatar);
Vue.component('PopoverWrapper', PopoverWrapper);
Vue.component('OauthIcon', OauthIcon);
Vue.component('OauthLinks', OauthLinks);
Vue.component('SpinnerOverlay', SpinnerOverlay);

document.addEventListener('DOMContentLoaded', () => {
  Vue.use(ButtonPlugin);
  Vue.use(CardPlugin);
  Vue.use(SpinnerPlugin);
  Vue.use(FormSelectPlugin);
  Vue.use(PaginationNavPlugin);
  Vue.use(TabsPlugin);
  Vue.use(DropdownPlugin);
  Vue.use(TablePlugin);
  Vue.use(EmbedPlugin);
  Vue.use(ModalPlugin);
  Vue.use(ImagePlugin);

  import('@app_shared/rangetouch');

  new Vue({
    el: '#sidebars_app',
    store,
    components: { SidebarsApp },
    template: '<SidebarsApp/>',
  });

  new Vue({
    el: '#nav_icons_app',
    store,
    components: { NavIconsApp },
    template: '<NavIconsApp/>',
  });

  if (document.getElementById('lesson_app')) {
    new Vue({
      el: '#lesson_app',
      store,
      components: {
        LessonApp: () => import('../application/lesson/app.vue'),
      },
      template: '<LessonApp/>',
    });
  }

  (async () => {
    if (!document.getElementById('edu_materials_app')) return;

    const { router } = await import('@app/edu_materials/router');

    new Vue({
      el: '#edu_materials_app',
      router,
      store,
      components: {
        App: () => import('@app/edu_materials/app'),
      },
      template: '<App/>',
    });
  })();

  (async () => {
    if (!document.getElementById('personal_app')) return;

    const { default: router } = await import('../application/personal/router');

    new Vue({
      el: '#personal_app',
      router,
      store,
      components: {
        PersonalApp: () => import('../application/personal/app.vue'),
      },
      template: '<PersonalApp/>',
    });
  })();

  if (document.getElementById('testimonials_app')) {
    new Vue({
      el: '#testimonials_app',
      store,
      components: {
        TestimonialsApp: () => import('../application/testimonials/app.vue'),
      },
      template: '<TestimonialsApp/>',
    });
  }

  (async () => {
    if (!document.getElementById('edu_materials_cart_app')) return;

    new Vue({
      el: '#edu_materials_cart_app',
      store,
      components: { App: () => import('../application/edu_materials_cart/app.vue') },
      template: '<App/>',
    });
  })();
});
