import { computed } from '@vue/composition-api';

import { selectedCurrencyIsoRef } from '@app_shared/ecommerce.js.erb';



export const materialsPath = '/materialy';
export const initMaterialsPath = '/materialy/init';
export const materialsCartPath = '/materialy/cart';

const cartOrderPath = '/materialy/order';
export const cartOrderPathWithCurrencyRef = computed(() => cartOrderPath + '?currency=' + selectedCurrencyIsoRef.value);
