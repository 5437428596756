<template lang="pug">
  div
    Star(
      v-for="(_, index) in (new Array(max))"
      @click.native="$emit('input', index + 1)"
      :position="index + 1"
      :key="index + 1"
      :value="value"
    )
</template>

<script>
  import Star from './Star';

  export default {
    components: { Star },

    props: {
      min: {
        type: Number,
        default: 1,
      },

      max: {
        type: Number,
        default: 1,
      },

      value: {
        type: Number,
        default: 0,
      },
    },
  };
</script>
