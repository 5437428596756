export default {
  id: null,
  statusChanged: false,
  submitRejectedDueToStatusChange: false,
  loaded: false,
  number: null,
  diploma: null,
  final: null,
  name: null,
  description: null,
  question: null,
  status: null,
  statusWillChangeAt: null,
  availableToContinue: null,
  authorName: null,
  currentTextToSpeech: null,
  speechEnabled: false,
  statusUrl: null,
  nextUrl: null,
  previousUrl: null,
  content: {},
  homework: {},
  serverTime: {},
  complaint: {},
  selectedLessonService: null,
  selectedVideoContent: null,
  timerEnabled: false,
  timerSoundEnabled: false,
  unauthorized: false,
  courseCompleted: undefined,

  tours: {
    active: null,
  },
};
