<template>
  <header class="sidebar-header" ref="header">
    <div class="close-button-container">
      <CloseButton></CloseButton>
    </div>

    <slot></slot>
  </header>
</template>

<script>
  import CloseButton from './CloseButton';

  import scrollDisablerMixin from '@app_shared/mixins/scroll-disabler-mixin';

  export default {
    mixins: [scrollDisablerMixin('header')],

    components: {
      CloseButton
    }
  };
</script>

<style scoped lang="scss">
  .sidebar-header {
    padding: 1.4rem;
    position: relative;
    width: 100%;
    @include main-gradient;

    color: white;

    .close-button-container {
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }
</style>