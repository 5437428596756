import Vue from 'vue';

import App from './App';

document.addEventListener('DOMContentLoaded', () => {
  const clientFeedbackAppContainers = Array.from(document.querySelectorAll('.client-feedback-app'));

  clientFeedbackAppContainers.forEach((container) => {
    const {
      submitUrl,
      skipUrl,
    } = container.dataset;

    const app = new Vue({
      components: { App },

      template: '<App :skip-url="skipUrl" :submit-url="submitUrl" />',

      setup () {
        return {
          submitUrl,
          skipUrl,
        };
      },
    });

    app.$mount(container);
  });
});
