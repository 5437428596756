export default {
  initialize (state, { id, number, description, name, status, final, statusWillChangeAt, content, homework, question, diploma, complaint, authorName, availableToContinue, inAcademicLeave, tours, nextUrl, previousUrl, statusUrl, timerEnabled, timerSoundEnabled, courseCompleted }) {
    state.id = id;
    state.number = number;
    state.diploma = diploma;
    state.final = final;
    state.description = description;
    state.question = question;
    state.name = name;

    state.complaint = complaint;
    state.status = status;
    state.statusWillChangeAt = statusWillChangeAt;
    state.authorName = authorName;
    state.availableToContinue = availableToContinue;
    state.inAcademicLeave = inAcademicLeave;

    state.content = content;
    state.homework = homework;
    state.tours = tours;
    state.nextUrl = nextUrl;
    state.previousUrl = previousUrl;
    state.statusUrl = statusUrl;

    state.submitRejectedDueToStatusChange = false;
    state.statusChanged = false;
    state.loaded = true;
    state.timerEnabled = timerEnabled;
    state.timerSoundEnabled = timerSoundEnabled;
    state.courseCompleted = courseCompleted;
  },

  setSelectedMainContentVariant (state, newVariant) {
    state.content.selectedMainContentVariant = newVariant;
  },

  setSpellcheck (state, value) {
    state.content.spellcheck = value;
  },

  setContentRestricted (state, newAccess) {
    state.content.restricted = newAccess;
  },

  setHomeworkValidated (state) {
    state.homework.validated = true;
  },

  updateHomework (state, homework) {
    state.homework = Object.assign({}, state.homework, homework);
  },

  setCurrentTextToSpeech (state, newId) {
    state.currentTextToSpeech = newId;
  },

  setSpeechEnabled (state, value) {
    state.speechEnabled = value;
  },

  setComplainableId (state, newId) {
    state.complaint.complainableId = newId;
  },

  setComplainableComplained (state, complainable) {
    complainable.complained = true;
  },

  setComplaintSubmitting (state, bool = true) {
    state.complaint.submitting = bool;
  },

  setStatusChanged (state, bool = true) {
    state.statusChanged = bool;
  },

  setSubmitRejectedDueToStatusChange (state, bool = true) {
    state.submitRejectedDueToStatusChange = bool;
  },

  setAvailableToContinue (state, bool = true) {
    state.availableToContinue = bool;
  },

  setLoaded (state, bool = true) {
    state.loaded = bool;
  },

  updateContent (state, newContent) {
    state.content = Object.assign({}, state.content, newContent);
  },

  setActiveTour (state, tourName) {
    if (state.tours.active !== tourName) {
      state.tours.active = tourName;
    } else {
      state.tours.active = null;
    }
  },

  setSelectedLessonService (state, service) {
    state.selectedLessonService = service;
  },

  setSelectedVideoContent (state, content) {
    state.selectedVideoContent = content;
  },

  setUnauthorized (state, value) {
    state.unauthorized = value;
  },
};
