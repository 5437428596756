<template lang="pug">
  a.nav-link.notification-bell(href="#" @click.prevent="checkNotified" :class="{ empty: !shouldNotify }")
    i.fa.fa-bell-o
    .notification-bell-counter
      | {{newCount | limited}}
</template>

<script>
  import { mapState, mapMutations } from 'vuex';

  import { checkNotifiedPath } from '@app_shared/paths.js.erb';
  import { get } from '@shared/default-axios';

  export default {
    filters: {
      limited: (num) => (num > 9 ? '9+' : num)
    },

    data() {
      return {
        checked: false
      };
    },

    computed: {
      ...mapState('notifications', [
        'newCount',
        'timestamp'
      ]),

      shouldNotify () {
        return !(this.newCount === 0 || this.checked)
      }
    },

    methods: {
      ...mapMutations([
        'openSidebar'
      ]),

      checkNotified() {
        get(checkNotifiedPath, {
          params: {
            stamp: this.timestamp
          }
        });
        this.checked = true;
        this.openSidebar('notifications');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .notification-bell {
    &.empty {
      opacity: 0.5;

      .notification-bell-counter {
        display: none;
      }
    }

    position: relative;

    .notification-bell-counter {
      position: absolute;
      top: 17px;
      left: 4px;
      z-index: 1;
      display: inline-block;
      font-size: 13px;
      font-weight: bold;

      $size: 18px;
      width: $size;
      height: $size;
      line-height: $size;
      border: 1px solid #363f47;

      background: #e84500;
      text-align: center;
      border-radius: 50%;
      color: white;
    }
  }
</style>