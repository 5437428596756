<template lang="pug">
  video(
    ref="videoRef"
    :poster="posterUrl"
    playsinline
    preload="metadata"
    controls
  )
    source(
      v-if="!hlsSupported && appleNativeSupported"
      :src="url"
      type="application/vnd.apple.mpegURL"
    )
</template>

<script>
  import { onBeforeUnmount, ref, watch } from '@vue/composition-api';
  import Hls from 'hls.js';

  export default {
    name: 'HlsStream',

    props: {
      url: {
        type: String,
        required: true,
      },

      posterUrl: {
        type: String,
        default: null,
      },
    },

    setup (props, { emit }) {
      const videoRef = ref(null);

      const { hlsSupported, appleNativeSupported } = useHls(props.url, videoRef, emit);

      return {
        videoRef,
        hlsSupported,
        appleNativeSupported,
      };
    },
  };

  function useHls (url, videoElementRef, emit) {
    const hlsSupported = Hls.isSupported();
    const appleNativeSupported = !!document.createElement('VIDEO').canPlayType('application/vnd.apple.mpegURL');
    const hls = new Hls({ autoStartLoad: false });

    if (hlsSupported) {
      watch(videoElementRef, (now, was) => {

        if (was) hls.detachMedia();
        if (now) {
          if (hlsSupported) {
            hls.loadSource(url);
            hls.attachMedia(now);
            emit('hls', hls);
          }

          now.addEventListener('play', () => hls.startLoad());
        }
      }, { immediate: true });
    }

    onBeforeUnmount(() => hls?.destroy());

    return {
      hls,
      hlsSupported,
      appleNativeSupported,
    };
  }
</script>
