<template>
  <SidebarWrapper :position="position">
    <SidebarMainHeader>
      <a href="/">
        <img :src="logo" alt="PM logo">
      </a>
    </SidebarMainHeader>

    <ScrollWrapper>
      <ul class="list-unstyled sidebar-body">
        <NavItem :item="{ href: '/', title: 'Главная' }" />
        <NavItem v-for="(item, index) in navItems" :key="index" :index="index" :item="item" />
      </ul>
    </ScrollWrapper>
  </SidebarWrapper>
</template>

<script>
  import SidebarWrapper from '../shared/SidebarWrapper';
  import SidebarMainHeader from '../shared/SidebarMainHeader';
  import NavItem from '../shared/NavItem';

  import sidebarWrapperMixin from '../shared/mixins/sidebar-wrapper-mixin';

  import { mapState } from 'vuex';

  export default {
    mixins: [sidebarWrapperMixin],

    computed: {
      ...mapState('navigation', [
        'navItems',
        'logo'
      ])
    },

    components: {
      SidebarWrapper,
      SidebarMainHeader,
      NavItem
    }
  };
</script>
