import { ref } from '@vue/composition-api';
import Vue from 'vue';
import Plyr from '../shared/components/players/Plyr';

export default function attachPlyrIframe (element) {
  const iframeHtml = element.innerHTML;

  const app = new Vue({
    components: {
      Plyr,
    },

    template: `
                <Plyr ref="root" @player="emitPlayer">
                  <div v-html="iframeHtml"></div>
                </Plyr>
              `,

    setup () {
      const rootRef = ref(null);

      return {
        root: rootRef,
        iframeHtml,

        emitPlayer (player) {
          const event = new CustomEvent('plyr:player', {
            detail: { player },
            bubbles: true,
          });

          rootRef
            .value
            .$el
            .dispatchEvent(event);
        },
      };
    },
  });

  app.$mount(element);
}
