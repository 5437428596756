import { camelizeKeys, decamelizeKeys } from 'humps';

const applyConfig = (axiosInstance) => {
  axiosInstance.interceptors.request.use((config) => {
    if (config.data)
      config.data = decamelizeKeys(config.data, { split: /(?=[A-Z0-9])/ });

    if (config.params)
      config.params = decamelizeKeys(config.params, { split: /(?=[A-Z0-9])/ });

    return config;
  });

  axiosInstance.interceptors.response.use((response) => {
    if (response.data)
      response.data = camelizeKeys(response.data);

    return response;
  }, (error) => {
    if (error.response && error.response.data)
      error.response.data = camelizeKeys(error.response.data);

    throw error;
  });
};

export {
  applyConfig,
};