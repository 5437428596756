<template>
  <div :class="['sidebar-header', 'gradient-royal-purple-to-boston-blue']" ref="header">
    <div class="header-title">
      <slot></slot>
    </div>

    <div class="close-button-container">
      <CloseButton></CloseButton>
    </div>
  </div>
</template>

<script>
  import CloseButton from './CloseButton';

  import gradientPickerMixin from './mixins/gradient-picker-mixin';

  import scrollDisablerMixin from '@app_shared/mixins/scroll-disabler-mixin';

  export default {
    mixins: [scrollDisablerMixin('header'), gradientPickerMixin],

    components: {
      CloseButton
    }
  };
</script>

<style scoped lang="scss">
  .sidebar-header {
    display: flex;
    justify-content: space-between;
    color: white;

    > div {
      padding: 12px;
    }

    .header-title {
      font-weight: 500;
      ::v-deep i.fa {
        margin-right: 4px;
      }
    }

    .close-button-container {

    }
  }
</style>