export default {
  namespaced: true,

  state: {
    navItems: [],
    logo: ''
  },

  mutations: {
    initialize(state, { navItems, logo }) {
      state.navItems = navItems;
      state.logo = logo;
    }
  }
};