<template lang="pug">
  .modal.fade(data-backdrop="static" data-keyboard="false"): .modal-dialog.rounded: .modal-content.rounded.px-md-5.py-4
    template(v-if="submitAPI.result")
      .modal-body.text-center
        h3 Благодарим за оценку!

      .modal-footer.d-flex.flex-column-reverse.flex-md-row.justify-content-center.align-items-center.border-0
        button.btn.btn-light.border(@click.prevent="closeModal") Закрыть
    template(v-else)
      .modal-body.text-center.pt-5.pb-4
        h4
          | Ваше мнение очень важно для нас!
          br
          | Пожалуйста, расскажите нам о пройденном онлайн-курсе.
        .my-4
          .form-group
            label Как Вы оцениваете контент?
            StarsInput(:min="1" :max="10" v-model="form.contentRating.value")
          .form-group
            label Как Вы оцениваете техническую часть?
            StarsInput(:min="1" :max="10" v-model="form.usabilityRating.value")
          .form-group.mb-0
            label Насколько вероятно, что Вы порекомендуете нашу онлайн-школу своим коллегам, друзьям и знакомым?
            StarsInput(:min="1" :max="10" v-model="form.retentionRating.value")
      .modal-footer.d-flex.flex-column-reverse.flex-md-row.justify-content-between.align-items-center.border-0.pb-5
        .my-3.my-md-0
          a.text-oslo-gray(href="#" @click.prevent="onSkip")
            span.spinner-border.spinner-border-sm(v-if="skipAPI.pending || skipAPI.result")
            span(v-else) Не хочу отвечать
        .my-3.my-md-0
          button.btn.btn-blue-to-green.btn-lg(@click.prevent="onSubmit")
            span.spinner-border.spinner-border-sm(v-if="submitAPI.pending || submitAPI.result")
            span(v-else) Отправить
</template>

<script>
  import { onMounted, ref } from '@vue/composition-api';

  import { usePromise } from '@shared/composition-utils/use-promise';
  import { useForm, useRequiredValidation } from '@shared/composition-utils/use-form';
  import { post } from '@shared/transforming-keys-axios';

  import StarsInput from './components/StarsInput';
  import flash from '@shared/flash';

  /* global $ */

  export default {
    components: {
      StarsInput,
    },

    props: {
      skipUrl: {
        type: String,
        required: true,
      },

      submitUrl: {
        type: String,
        required: true,
      },
    },

    setup (props, context) {
      const form = useForm({
        contentRating: {
          value: ref(null),
          validations: [useRequiredValidation()],
        },

        usabilityRating: {
          value: ref(null),
          validations: [useRequiredValidation()],
        },

        retentionRating: {
          value: ref(null),
          validations: [useRequiredValidation()],
        },
      });

      const submitAPI = usePromise(() => post(props.submitUrl, {
        clientFeedback: {
          contentRating: form.contentRating.value,
          usabilityRating: form.usabilityRating.value,
          retentionRating: form.retentionRating.value,
        },
      }));

      const skipAPI = usePromise(() => post(props.skipUrl));

      const openModal = () => $(context.root.$el).modal('show');
      const closeModal = () => $(context.root.$el).modal('hide');

      onMounted(openModal);

      return {
        form,
        submitAPI,
        skipAPI,

        something: ref(null),

        onSubmit () {
          if (submitAPI.pending || submitAPI.result) return;

          if (!form.isValid) {
            flash({ alert: 'Пожалуйста, заполните форму полностью.' });
          } else {
            submitAPI.execute();
          }
        },

        onSkip () {
          if (submitAPI.result) return;

          skipAPI.execute();
          closeModal();
        },

        closeModal,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .modal-dialog {
    max-width: 640px
  }

  label {
    font-weight: 500;
    color: $scorpion;
  }
</style>
