export default {
  fetch (key) {
    let options = localStorage.getItem(key);

    try {
      options = JSON.parse(options);
    } catch(e) {
      options = {};
    }

    if (typeof options !== 'object' || options === null) {
      options = {};
    }

    return options;
  },

  set (key, options) {
    const oldOptions = this.fetch(key);
    const newOptions = { ...oldOptions, ...options };

    localStorage.setItem(key, JSON.stringify(newOptions));
  },
};
