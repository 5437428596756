<template>
  <transition name="delayed-fade">
    <div v-if="shouldShow" class="item-missing">
      <slot></slot>
    </div>
  </transition>
</template>

<script>
  export default {
    props: {
      shouldShow: {
        type: Boolean,
        required: true
      }
    }
  };
</script>

<style scoped lang="scss">
  .item-missing {
    padding: 10px;
    color: $oslo-gray;
    font-weight: 400;
    font-size: 15px;
    text-align: center;
  }
</style>