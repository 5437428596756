import store from '@app_shared/store';

export default function initLessonView (lesson) {
  const session = Number(new Date());

  return App.cable.subscriptions.create({
    channel: 'LessonsViewChannel',
    lesson,
    session
  }, {
    received (data) {
      switch (data.command) {
        case 'restrict':
          if (data.initiator !== session) {
            store.commit('lesson/setContentRestricted', true);
          }
          break;

        case 'restore':
          store.commit('lesson/setContentRestricted', false);
          break;

        case 'status_changed':
          if (data.status !== store.state.lesson.status) {
            store.commit('lesson/setStatusChanged', true);
          }
          break;
      }
    },

    requestAccess () {
      this.perform('request_access', {
        lesson,
        session
      });
    }
  });
};