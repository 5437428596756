<template>
  <li v-if="item.href">
    <a :href="item.href" :data-method="method" v-bind="item.html">
      <span v-html="item.title"></span>
    </a>
  </li>
  <li v-else>
    <a href="#" data-toggle="collapse" :data-target="'#navigation_collapse_' + collapseId">
      <span>
        <span v-html="item.title"></span>
      </span>
    </a>
    <ul :id="'navigation_collapse_' + collapseId" class="list-unstyled collapse">
      <li v-for="(subitem, index) in item.content" :key="index">
        <a :href="subitem.href" v-bind="subitem.html">
          <span v-html="subitem.title"></span>
        </a>
      </li>
    </ul>
  </li>
</template>

<script>
  export default {
    props: {
      item: {
        type: Object,
        required: true,
      },

      method: {
        type: String,
        required: false,
      },

      index: {
        type: Number,
        required: true,
      },
    },

    computed: {
      collapseId () {
        return this.index;
      },
    },
  };
</script>

<style scoped lang="scss">
  @mixin personal-item-border {
    border-bottom: 1px solid #eaeaeb;
  }

  li {
    > a {
      padding: 0 1.4rem;
      width: 100%;
      display: block;
      color: $scorpion;
      transition: background .2s;

      ::v-deep i {
        color: $oslo-gray;
        margin-left: 4px;
        vertical-align: baseline;
        transition: transform .5s;
      }

      > span {
        display: block;
        padding: .8rem 0;
        @include personal-item-border;
      }

      &[aria-expanded="true"] {
        ::v-deep span {
          border-bottom-color: transparent;

          i.fa-angle-down {
            transform: rotate(-180deg);
          }
        }
      }

      &:hover {
        background: white;
        > span {
          border-bottom-color: transparent;
        }
      }
    }

    ul {
      background: $porcelain;
      box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.3) inset;

      li, a, span { display: block; }

      > li {
        > a {
          color: $scorpion;

          span {
            padding: .8rem 0;
          }
        }
      }
    }

    &.logout {
      > a span {
        border-bottom: none;
        text-align: center;
      }
    }
  }
</style>
