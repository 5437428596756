import attachPlyrHls from './attach-plyr-hls';
import attachPlyrIframe from './attach-plyr-iframe';

document.addEventListener('DOMContentLoaded', () => {
  document
    .querySelectorAll('.plyr-hls')
    .forEach(attachPlyrHls);

  document
    .querySelectorAll('.plyr-embedded')
    .forEach(attachPlyrIframe);
});
