export default {
  homeworkDone (_, getters) {
    return !!getters.homeworkDoneAt;
  },

  homeworkDoneAt ({ homework }) {
    return homework?.doneAt;
  },

  isActive ({ status }) {
    return status > 0 && status < 5;
  },

  complaintType ({ status }) {
    if (status === 2) {
      return 'for_answer';
    } else if (status === 4) {
      return 'for_comment';
    }
  },

  complainable ({ status, homework, complaint, availableToContinue }) {
    let collection;

    if (!availableToContinue) return undefined;

    if (status === 2)
      collection = 'answers';
    else if (status === 4)
      collection = 'comments';
    else
      return undefined;

    return homework[collection].find(item => item.id === complaint.complainableId);
  },
};
