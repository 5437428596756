import state from './lesson/state';
import getters from './lesson/getters';
import mutations from './lesson/mutations';
import actions from './lesson/actions';

export default {
  namespaced: true,

  state,

  getters,

  mutations,

  actions
};