import { getEcommercePrice, selectedCurrencyIsoRef } from '@app_shared/ecommerce.js.erb';
import { defaultErrorHandler } from '@shared/utils/default-error-handler';
import { responseDataErrorHandler } from '@shared/utils/response-data-error-handler';
import { reactive, computed } from '@vue/composition-api';
import axios from '@shared/transforming-keys-axios';

import flash from '@shared/flash';

const sum = (a, b) => a + b;

const cart = reactive({
  materials: [],

  totalPrice: computed(() => ({
    cents: cart.materials.map(material => getEcommercePrice(material)?.cents || 0).reduce(sum, 0),
    currencyIso: selectedCurrencyIsoRef.value,
  })),

  materialsCount: computed(() => cart.materials.length),

  isEmpty: computed(() => cart.materialsCount === 0),

  inCart: material => cart.materials.some(({ id }) => id === material.id),

  addMaterial (materialUrl) {
    return axios
      .post(materialUrl)
      .then(({ data: { material } }) => {
        flash({ success: 'Материал был добавлен в вашу корзину!' });
        cart.materials.push(material);
      }).catch(responseDataErrorHandler)
      .catch(defaultErrorHandler);
  },

  removeMaterial (materialId) {
    const material = cart.materials.find(({ id }) => materialId === id);

    return axios
      .delete(material.removeFromCartUrl)
      .then(() => {
        cart.materials = cart.materials.filter(({ id }) => materialId !== id);
      }).catch(responseDataErrorHandler)
      .catch(defaultErrorHandler);
  },
});

/* global App */
App.cart = cart;

export {
  cart,
};
