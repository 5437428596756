<template>
  <SidebarWrapper :position="position">
    <SidebarSecondaryHeader>
      <i class="fa fa-bell-o"></i> Уведомления
    </SidebarSecondaryHeader>

    <ScrollWrapper>
      <ul class="list-unstyled notifications-wrapper">
        <NotificationItem v-for="(item, index) in notifications" :key="index" :i="index" :item="item" :newCount="newCount" />
      </ul>

      <ItemMissing :should-show="!notifications.length">
        Нет уведомлений
      </ItemMissing>

      <BottomButtons v-if="notifications.length"></BottomButtons>
    </ScrollWrapper>

  </SidebarWrapper>
</template>

<script>
  import SidebarWrapper from '../shared/SidebarWrapper';
  import SidebarSecondaryHeader from '../shared/SidebarSecondaryHeader';
  import ItemMissing from '../shared/ItemMissing';
  import NotificationItem from './components/NotificationItem';
  import BottomButtons from './components/BottomButtons'

  import sidebarWrapperMixin from '../shared/mixins/sidebar-wrapper-mixin';

  import { mapState } from 'vuex';

  export default {
    mixins: [sidebarWrapperMixin],

    computed: {
      ...mapState('notifications', [
        'notifications',
        'newCount'
      ])
    },

    components: {
      SidebarWrapper,
      SidebarSecondaryHeader,
      NotificationItem,
      BottomButtons,
      ItemMissing
    }
  }
</script>