import { throttle } from 'underscore';
import reactiveLocalstorageValue from './reactive-localstorage-value';

const usePlyrMediaResumer = async (savedProgressKey, player) => {
  const savedProgressRef = reactiveLocalstorageValue(savedProgressKey);

  await whenPlaying(player);

  player.currentTime = savedProgressRef.value || 0;

  startSavingProgress(player, savedProgressRef);
};

const eventListenerAsPromise = eventType => player => new Promise(resolve => player.once(eventType, resolve, { once: true }));
const whenPlaying            = eventListenerAsPromise('playing');

function startSavingProgress (player, savedProgressRef) {
  player.on('timeupdate', throttle(() => {
    if (player.currentTime > 0)
      savedProgressRef.value = player.currentTime;
  }, 1000));
}


export default usePlyrMediaResumer;
