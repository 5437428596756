<template>
  <div class="close-button" @click="closeSidebar">
    &times;
  </div>
</template>

<script>
  import { mapMutations } from 'vuex';

  export default {

    methods: {
      ...mapMutations([
        'closeSidebar'
      ])
    }
  };
</script>

<style scoped lang="scss">
  .close-button {
    font-size: 38px;
    line-height: 24px;
    cursor: pointer;
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }
</style>