

export const internalPath = '/admin/dashboard';
export const corporatePath = '/companies';
export const partnerPath = '/sub_admin/partners';
export const teacherPath = '/sub_admin/teachers';
export const outerPath = '/back-to-outer';
export const checkNotifiedPath = '/notified';
export const vitnPath = '/personal/premium_points_transfers';
export const logoutPath = '/logout';
export const resolvePersonalIconPopoverPath = '/popovers/personal_icon/resolve';
export const notificationsPath = '/notifications';
export const personalTasksPath = '/personal/tasks';
export const personalStatsPath = '/personal/statistics';
export const personalPath = '/personal';
export const personalEducationPath = '/personal/education';
export const personalAchievementsPath = '/personal/achievements';
export const personalEduMaterialsPath = '/personal/edu_materials';
export const personalSettingsPath = '/personal/settings';
export const personalInvoiceHistoryPath = '/personal/invoice-history';
export const personalVitnTransfersPath = '/personal/premium_points_transfers';
export const serverTimePath = '/server-time';
export const onlinePath = '/schedule';
export const eduMaterialsPath = '/materialy';
