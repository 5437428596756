export const defaultPlyrOptions = {
  clickToPlay: true,

  hideControls: true,

  invertTime: false,

  settings: ['quality', 'speed'],

  speed: {
    selected: 1,
    options: [1, 1.25, 1.5, 1.75, 2],
  },

  i18n: {
    speed: 'Скорость',
    normal: 'Нормальная',
    rewind: 'Отмотать {seektime}с',
    fastForward: 'Вперед {seektime}с',
    play: 'Старт',
    pause: 'Пауза',
    settings: 'Настройки',
    pip: 'В отдельном окне',
    enterFullscreen: 'В полный экран',
  },

  controls: ['rewind', 'play', 'fast-forward', 'progress', 'volume', 'current-time', 'settings', 'fullscreen', 'play-large', 'pip'],

  tooltips: {
    controls: true,
    seek: true,
  },

  ratio: '16:9',
};