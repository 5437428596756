<template>
  <aside :class="['sidebar-wrapper', position]" ref="aside">
    <slot></slot>
  </aside>
</template>

<script>
  import sidebarWrapperMixin from './mixins/sidebar-wrapper-mixin';

  export default {
    mixins: [sidebarWrapperMixin],
  };
</script>

<style scoped lang="scss">
  .sidebar-wrapper {
    display: flex;
    flex-direction: column;
    background-color: #f7f7f7;

    height: 100%;
    min-height: 0;
    width: 90%;
    min-width: 260px;
    position: fixed;
    top: 0;

    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);

    @include media-breakpoint-up(sm) {
      width: 360px;
    }

    @include media-breakpoint-up(lg) {
      width: 500px;
    }

    &.left {
      left: 0;
      margin-right: 10%;
    }

    &.right {
      right: 0;
      margin-left: 10%;
    }
  }
</style>