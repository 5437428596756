import Vue from 'vue';

import { ref } from '@vue/composition-api';
import Plyr from '@app_shared/components/players/Plyr';
import HlsStream from '../shared/components/players/HlsStream';

export default function attachPlyrHls (element) {
  const {
    hlsUrl,
    poster,
  } = element.dataset;

  const app = new Vue({
    components: {
      Plyr,
      HlsStream,
    },

    template: `
                <Plyr ref="root" @player="emitPlayer">
                  <HlsStream :url="hlsUrl" :posterUrl="poster"></HlsStream>
                </Plyr>
              `,

    setup () {
      const rootRef = ref(null);

      return {
        root: rootRef,
        hlsUrl,
        poster,

        emitPlayer (player) {
          const event = new CustomEvent('plyr:player', {
            detail: { player },
            bubbles: true,
          });

          rootRef
            .value
            .$el
            .dispatchEvent(event);
        },
      };
    },
  });

  app.$mount(element);
}
