<template lang="pug">
  transition(name="appear")
    ul.navbar-nav(v-if="initialized")
      template(v-if="userSignedIn")
        li.nav-item(v-if="userIsOuter")
          a.nav-link(:href="outerPath")
            i.fa.fa-undo

        li.nav-item(v-if="userIsTeacher")
          a.nav-link(:href="teacherPath")
            i.fa.fa-university

        li.nav-item(v-if="userIsPartner")
          a.nav-link(:href="partnerPath")
            i.fa.fa-briefcase

        li.nav-item(v-if="userIsCorporate")
          a.nav-link(:href="corporatePath")
            i.fa.fa-building

        li.nav-item(v-if="userIsInternal")
          a.nav-link(:href="internalPath")
            i.fa.fa-cogs

        li.nav-item
          VitnIcon

      li.nav-item
        ShoppingCartIcon

      template(v-if="userSignedIn")
        li.nav-item
          NotificationIcon

      li.nav-item
        PersonalIcon

</template>

<script>
  import VitnIcon from './components/VitnIcon';
  import ShoppingCartIcon from './components/ShoppingCartIcon';
  import PersonalIcon from './components/PersonalIcon';
  import NotificationIcon from './components/NotificationIcon';

  import { mapState, mapGetters } from 'vuex';

  import { internalPath, outerPath, corporatePath, partnerPath, teacherPath } from '@app_shared/paths.js.erb';

  export default {
    components: {
      VitnIcon,
      PersonalIcon,
      NotificationIcon,
      ShoppingCartIcon,
    },

    computed: {
      ...mapState(['initialized']),

      ...mapGetters(['userSignedIn', 'userIsTeacher', 'userIsPartner', 'userIsCorporate', 'userIsOuter', 'userIsInternal']),
    },

    created () {
      this.internalPath = internalPath;
      this.outerPath = outerPath;
      this.corporatePath = corporatePath;
      this.partnerPath = partnerPath;
      this.teacherPath = teacherPath;

      this.themedTooltip = `
        <div class="tooltip tooltip-light" role="tooltip">
          <div class="arrow"></div>
          <div class="tooltip-inner"></div>
        </div>
      `;
    },
  };
</script>

<style scoped>
  .appear-enter-active, .appear-leave-active {
    transition: opacity .2s, transform .2s;
  }

  .appear-enter {
    opacity: .2;
  }

  .appear-enter, .appear-leave-to {
    opacity: 0;
  }
</style>
