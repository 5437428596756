<template lang="pug">
  div(data-toggle="tooltip" :title="title")
    slot
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true
      },

      template: {
        type: String,
        required: false
      }
    },

    computed: {
      options() {
        const opts = {
          title: this.title
        };

        if (typeof this.template === 'string') {
          opts.template = this.template;
        }
      }
    },

    watch: {
      title(now, was) {
        $(this.$el).tooltip('dispose');
        this.$nextTick(() => {
          $(this.$el).tooltip(this.options);
        });
      }
    },

    mounted() {
      $(this.$el).tooltip(this.options);
    }
  };
</script>

<style scoped lang="scss">
  [data-toggle="tooltip"] {
    cursor: inherit;
  }
</style>