import { render, staticRenderFns } from "./SpinnerOverlay.vue?vue&type=template&id=4d830f36&scoped=true&lang=pug&functional=true&"
import script from "./SpinnerOverlay.vue?vue&type=script&lang=js&"
export * from "./SpinnerOverlay.vue?vue&type=script&lang=js&"
import style0 from "./SpinnerOverlay.vue?vue&type=style&index=0&id=4d830f36&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "4d830f36",
  null
  
)

export default component.exports