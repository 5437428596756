<template lang="pug">
  div.fa-stack
    i.fa.fa-stack-2x(:class="`fa-${base} text-${baseColor}`")
    i.fa.fa-stack-1x(:class="`fa-${icon} text-${iconColor}`")
</template>

<script>
  export default {
    props: {
      base: {
        type: String,
        default: 'circle'
      },

      baseColor: {
        type: String,
        default: 'red'
      },

      icon: {
        type: String,
        default: 'exclamation-triangle'
      },

      iconColor: {
        type: String,
        default: 'white'
      }
    }
  };
</script>