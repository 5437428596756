<template>
  <div class="modal fade" v-bind="closableOptions">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" :class="headerClass">
          <slot name="header"></slot>

          <button v-if="this.closable" class="close" data-dismiss="modal" type="button">
            <span>
              &times;
            </span>
          </button>
        </div>

        <div class="modal-body" :class="bodyClass">
          <slot></slot>
        </div>

        <div v-if="$slots.footer" class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      headerClass: {
        type: [String, Array, Object],
      },

      bodyClass: {
        type: [String, Array, Object],
        default: 'p-3',
      },

      closable: {
        type: Boolean,
        default: true,
      },
    },

    computed: {
      closableOptions () {
        return this.closable ? {} : { 'data-backdrop': 'static', 'data-keyboard': 'false' };
      },
    },
  };
</script> 
