<template>
  <div :class="['progress-dot', color, { current: position === current }]"></div>
</template>

<script>
  export default {
    props: {
      position: {
        type: Number,
        required: true
      },

      current: {
        type: Number,
        required: true
      },

      done: {
        type: Boolean,
        required: true
      }
    },

    computed: {
      color() {
        if (this.position > this.current) {
          return 'text-alto';
        } else if (this.done) {
          return 'text-java';
        } else if (this.position === this.current) {
          return 'text-fuel-yellow';
        } else {
          return 'text-amaranth'
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .progress-dot {
    background: currentColor;
    position: relative;
    z-index: 1;

    &.current {
      &::before, &::after {
        z-index: 0;
        content: '';
        position: absolute;
        border-radius: 50%;
      }

      &::before {
        top: -2px;
        bottom: -2px;
        left: -2px;
        right: -2px;
        background: $alto;
        opacity: 0.8;
      }

      &::after {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: currentColor;
      }
    }
  }
</style>