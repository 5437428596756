<template lang="pug">
  div.partial-collapse
    .content(:style="style" :class="{ show: show || !shouldCollapse }")
      .content-inner-wrapper(ref="contentInnerWrapper")
        slot
    .d-flex.justify-content-between.d-sm-block.mt-2(v-if="shouldCollapse || $slots.buttons")
      a.btn.btn-sm.btn-light.border.mr-2.px-2.px-sm-3(v-if="shouldCollapse" href="#" @click.prevent="show = !show")
        template(v-if="show")
          i.fa.fa-fw.fa-angle-up
          | Скрыть
        template(v-else)
          i.fa.fa-fw.fa-angle-down
          | Раскрыть
      slot(name="buttons")
</template>

<script>
  import { throttle } from 'underscore';
  import { scrollTo } from 'vue-scrollto';

  export default {
    props: {
      collapsedHeight: {
        type: [Number, String],
        default: 300
      }
    },

    data() {
      return {
        show: false,
        initialHeight: 0
      };
    },

    computed: {
      style() {
        return {
          'max-height': this.show ? `${this.initialHeight}px` : `${this.collapsedHeight}px`
        };
      },

      shouldCollapse() {
        return this.collapsedHeight < this.initialHeight;
      }
    },

    watch: {
      show(now, was) {
        if (!was && now) {
          this.setInitialHeight();
        } else if (was && !now && this.$el.offsetTop < window.scrollY) {
          scrollTo(this.$el, 400, { offset: -140 });
        }
      }
    },

    mounted() {
      this.setInitialHeight();
      this.throttledSetSize = throttle(this.setInitialHeight, 1000 / 60);

      window.addEventListener('resize', this.throttledSetSize);
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.throttledSetSize);
    },

    methods: {
      setInitialHeight() {
        this.initialHeight = this.$refs.contentInnerWrapper.clientHeight;
      }
    }
  };
</script>

<style scoped lang="scss">
  .partial-collapse .content {
    overflow: hidden;
    transition: max-height .35s;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: -1px;
      height: 1px;
      left: 0;
      right: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 1);
    }

    &.show {
      &::after {
        content: none;
      }
    }
  }
</style>