<template>
  <div class="custom-alert" :class="[`custom-alert-${computedColor}`, inline ? 'd-inline-flex' : 'd-flex', reverse && 'reverse']">
    <div class="alert-icon">
      <slot v-if="$slots.icon" name="icon"></slot>
      <span v-else class="fa-stack">
        <i class="fa fa-fw fa-circle fa-stack-2x text-white"></i>
        <i class="fa fa-fw fa-stack-1x" :class="defaultIconClass"></i>
      </span>
    </div>

    <div class="alert-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  const types = ['success', 'info', 'warning', 'danger'];
  const typeToColorMap = {
    success: 'success',
    info: 'boston-blue',
    warning: 'fuel-yellow',
    danger: 'amaranth'
  };
  const typeToIconMap = {
    success: 'fa-check',
    info: 'fa-info',
    warning: 'fa-exclamation',
    danger: 'fa-times'
  };

  export default {
    props: {
      type: {
        type: String,
        required: true,
        validator: value => types.includes(value)
      },

      inline: {
        type: Boolean,
        default: false
      },

      reverse: {
        type: Boolean,
        default: false
      },

      color: {
        type: String,
        required: false
      }
    },

    computed: {
      computedColor() {
        if (this.color)
          return this.color;
        else
          return typeToColorMap[this.type];
      },

      defaultIconClass() {
        return typeToIconMap[this.type];
      }
    }
  }
</script>