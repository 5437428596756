<template lang="pug">
  .popover-wrapper
    slot

    div.d-none(ref="popoverContentWrapper")
      div
        slot(name="popoverContent" v-bind="{ show, hide }")
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: false
      },

      placement: {
        type: String,
        default: 'bottom'
      },

      trigger: {
        type: String,
        default: 'manual'
      },

      html: {
        type: Boolean,
        default: false
      }
    },

    mounted() {
      this.jqueryEl = $(this.$el);
      this.initPopover();
    },

    methods: {
      initPopover() {
        this.jqueryEl.popover({
          title: this.title,
          content: this.$refs.popoverContentWrapper.firstChild || '',
          placement: this.placement,
          trigger: this.trigger,
          html: this.html
        });
      },

      show() {
        this.jqueryEl.popover('show');
      },

      hide() {
        this.jqueryEl.popover('hide');
      }
    }
  }
</script>

<style lang="scss">
  .popover {
    $color-base: $royal-purple;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
</style>