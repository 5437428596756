<template lang="pug">
  div.vue__form-social-auth
    small Войти с помощью: #{' '}
    ul.vue__form-social-auth-icons(:class="{ 'none-selected': selected === null }")
      li: a(:href="paths['user_facebook_omniauth_authorize_path']" @click.capture.prevent="triggerOauth" title="Facebook")
        OauthIcon(type="facebook" :active="selected === 'facebook'")

      li: a(:href="paths['user_google_oauth2_omniauth_authorize_path']" @click.capture.prevent="triggerOauth" title="Google")
        OauthIcon(type="google_oauth2" :active="selected === 'google_oauth2'")

      li: a(:href="paths['user_vkontakte_omniauth_authorize_path']" @click.capture.prevent="triggerOauth" title="Вконтаке")
        OauthIcon(type="vkontakte" :active="selected === 'vkontakte'")

      li: a(:href="paths['user_yandex_omniauth_authorize_path']" @click.capture.prevent="triggerOauth" title="Яндекс")
        OauthIcon(type="yandex" :active="selected === 'yandex'")

</template>

<script>
  import paths from '../paths.js.erb';

  export default {
    name: 'OauthLinks',

    props: {
      selected: {
        default: null,
        validator: (value) => value === null || typeof value === 'string',
      },
    },

    created () {
      this.paths = paths;
    },

    methods: {
      triggerOauth (event) {
        const href = event.currentTarget.getAttribute('href');
        const popupWindow = openPopupWindow(href, 'Вход PremiumManagement.com', window, 800, 600);
        popupWindow.focus();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .vue__form-social-auth {
    text-align: center;

    > small {
      color: #999;
      font-weight: 400;
      margin-right: 5px;
      display: inline-block;
    }

    .vue__form-social-auth-icons {
      display: inline-block;
      vertical-align: middle;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        display: inline-block;
        vertical-align: top;
        margin: 0 .2rem;

        a {
          display: block;
        }
      }

      &.none-selected li a img {
        opacity: 1;
      }

      li a:active img {
        transform: scale3d(.9, .9, .9);
      }
    }
  }
</style>
