<template functional lang="pug">
  img.user-avatar.dynamic(v-if="props.user.avatarUrl" :src="props.user.avatarUrl" :class="[data.class, data.staticClass]" loading="lazy")
  span.user-avatar.dynamic(v-else :style="{ 'background-color': props.user.avatarColor }" :class="[data.class, data.staticClass]")
    | {{props.user.name[0].toUpperCase()}}
</template>

<script>
  export default {
    name: 'UserAvatar',

    props: {
      user: {
        type: Object,
        required: true,
      },
    },
  };
</script>

<style scoped>
  .user-avatar {
    display: inline-block;
    user-select: none;
  }
</style>
